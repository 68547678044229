
import { Component, Vue, Prop } from 'vue-property-decorator'
import { commonModule, installationsModule, devicesModule, companyModule } from '@/store/modules/store-accessor'
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete';


@Component({
  components: {
    VuetifyGoogleAutocomplete
  }
})
export default class EditInstallationModal extends Vue {
  @Prop()
  payload!: any;

  loadingInstallations = true;

  get currInstallation () {
    return installationsModule.currentInstallation;
  }

  get currDevice () {
    return devicesModule.currentDevice;
  }

  get allInstallations() {
    return commonModule.installationsCombo;
  }

  get isLoadingInstallations () {
    return this.loadingInstallations;
  }
  get installationDescriptionIsEnabled() {
    return (companyModule.company?.preferences as any)?.installation_description;
  }
 
  deviceModel = {
    installation_id: this.currInstallation.info.id,
    device_id: this.currDevice.data.deviceId || '',
    device_name: this.currDevice.info.device_name || null,
    notes: this.currDevice.info.notes || null,
  }

  google_address:any;

  evt:any;

  tagRule= [
    (val: any) => !!val || this.$i18n.t("tag_required"),
  ]
  clientRule= [
  (val: any) => ((!!val || val == '') && val.name.length > 0) || this.$i18n.t("client_required"),
  ]

  areaRule= [
    (val: any) => !!val || this.$i18n.t("area_required"),
    (val: any) => val.length <= 30 || this.$i18n.t("form_area_length_rule"),
  ]

  installationRule = [
    (val: any) => !!val || this.$i18n.t("installation_required"),
  ]

  zipCodeRule= [
    (val: any) => val.length <= 15 || this.$i18n.t("form_zipCode_length_rule"),
  ]
  notesRule= [
    (val: any) => {
      if(val != null) return val.length <= 1000 || this.$i18n.t("form_notes_length_rule");
      else return true;
    },
  ]

  //Add Validation for two 



  installationText(item: any) {
    let installation_arr = item.is_business 
    ?
    [item.tag_id.split('_').pop(), `${item.address ? item.address : item.area }`, (item.description && this.installationDescriptionIsEnabled) ? item.description : '', `${item.entity_name} (${item.client_name})`, item.phone]
    :
    [item.tag_id.split('_').pop(), `${item.address ? item.address : item.area }`,(item.description && this.installationDescriptionIsEnabled) ? item.description : '', item.client_name, item.phone]

    return installation_arr.filter(Boolean).join(' | ')
  }

  async hideModal () {
    commonModule.hideModal();
  }

  async doUpdateDevice () {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      await devicesModule.editDevice(this.deviceModel);
      devicesModule.getDeviceInfo(this.deviceModel.device_id);
      devicesModule.getDevices('');
      this.hideModal();  
    }
  }

  async mounted() {
    commonModule.initSnackbar({});
    this.loadingInstallations = true;
    await commonModule.getInstallationsCombo();
    this.loadingInstallations = false;  
  }

  isNumber(evt:any) {
      evt = evt || window.event;
      let charCode = evt.which || evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
      return false;
  }
};
