
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { commonModule, clientsModule, companyModule } from '@/store/modules/store-accessor'
import { TimeUtil } from '@/helpers/TimeUtil'
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import { Icon } from "leaflet";
  
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});


@Component({
  components: {
    LMap, LTileLayer, LMarker
  }
})
export default class InstallationsSideviewGeneralTab extends Vue {
  @Prop() installation: Record<string, any>;

  center = [0, 0];
  zoom = 14;
  url = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
  markerCoords = [0, 0];

  formatDate (date: Date): string {
    return TimeUtil.formatDateTime(date)
  }

  @Watch("installation.info.latitude")
  updateMap() {
    this.center = [this.installation.info.latitude, this.installation.info.longitude];
    this.markerCoords = [this.installation.info.latitude, this.installation.info.longitude];
  }

  async mounted() {
    this.center = [this.installation.info.latitude, this.installation.info.longitude];
    this.markerCoords = [this.installation.info.latitude, this.installation.info.longitude];

    await companyModule.getCompanyInfo();
  }

  async selectClient (clientId: string) {
    await clientsModule.getClientsInfo(clientId)
    commonModule.showSideview({ name: 'clients-sideview' , payload: { clientId: clientId} })
  }


  clientInfoText() {
    if(!this.installation.info.client_firstname ) {
      return '-';
    }
    if(this.installation.info.client_is_business) {
      return `${this.installation.info.client_entity_name} (${this.installation.info.client_lastname} ${this.installation.info.client_firstname})`;
    }
    return  this.installation.info.client_lastname + ' ' + this.installation.info.client_firstname;
  }

  get descriptionIsEnabled() {
    return (companyModule.company?.preferences as any)?.installation_description;
  }
  
};
