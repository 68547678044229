
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { mapActions, mapState } from "vuex";
import { statsModule } from "@/store/modules/store-accessor";
import KeycloakSession from '@/helpers/keycloak-session'


@Component
export default class Sidebar extends Vue {

  get kcSession() {
    return new KeycloakSession()
  }

  @Prop()
  source: string;


  items= [
    {
      title: this.$i18n.t("dashboard"),
      icon: "mdi-home",
      link: "/dashboard",
      permissions: ["technician_company","distributor"],
    },
    {
      title: this.$i18n.t("consumption"),
      icon: "mdi-meter-electric-outline",
      link: "/consumption",
      permissions: ["technician_company","distributor"],
    },
    // {
    //   title: this.$i18n.t("savings"),
    //   icon: "mdi-leaf",
    //   link: "/savings",
    //   permissions: ["distributor"],
    // },
    {
      title: this.$i18n.t("inventory"),
      icon: "mdi-clipboard-list-outline",
      link: "/inventory",
      permissions: ["distributor"],
    },
    {
      title: this.$i18n.t("companies"),
      icon: "mdi-account-hard-hat-outline",
      link: "/companies",
      permissions: ["distributor"],
    },
    {
      title: this.$i18n.t("clients"),
      icon: "mdi-contacts-outline",
      link: "/clients",
      permissions: ["technician_company"],
    },
    {
      title: this.$i18n.t("installations"),
      icon: "mdi-home-city-outline",
      link: "/installations",
      permissions: ["technician_company"],
    },
    {
      title: this.$i18n.t("devices"),
      icon: "mdi-office-building-cog",
      link: "/devices",
      permissions: ["technician_company"],
    },
    {
      title: this.$i18n.t("map"),
      icon: "mdi-google-maps",
      link: "/map",
      permissions: ["technician_company","distributor"],
    },
    {
      title: this.$i18n.t("users"),
      icon: "mdi-account-group-outline",
      link: "/users",
      permissions: ["technician_company"],
    },
  ]

  sidebarWidth =  240
  sidebarMinWidth = 96

  hasAccess(permissions: []) {
    return permissions.some(el => this.kcSession.userHasRole(el));
  }


  get DRAWER_STATE() {
    return statsModule.drawerState;
  }
}


