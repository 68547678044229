
import { Component, Vue } from 'vue-property-decorator'
import AddInstallationAddressModal from './Modals/Installations/AddInstallationAddressModal.vue'

import { commonModule } from '@/store/modules/store-accessor'

@Component({
  components: {
    AddInstallationAddressModal,
  }
})
export default class TheSecondModal extends Vue {
  get modalName () {
    return commonModule.secondaryModal.name
  }

  get modalPayload () {
    return commonModule.secondaryModal.payload
  }
};
