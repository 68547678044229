import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators'
import MapApi from '@/api/map-api'
import DevicesApi from '@/api/devices-api';
@Module({ namespaced: true, name: 'map' })
export default class MapModule extends VuexModule {
  public markers: MapDto[] = [];
  public markersCount = 0;
  public isLoading = true;

  @Action({ commit: 'setMarkers'})
  async getMarkersAction (query: string): Promise<any[]> {
    const mapApi = new DevicesApi()
    const markers = await mapApi.getAllDevices(query)
    this.setIsLoading(false);
    return markers
  }

  @Mutation
  public setMarkers (markers: MapDto[]): void {
    markers.map(item => {
      item.address = `${item.address}`
      item.coord = [item.latitude, item.longitude]
    })
    this.markers = markers
  }

  @Mutation
  public setIsLoading(isLoading: boolean): void {
    this.isLoading = isLoading
  }
}