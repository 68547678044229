
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { commonModule, installationsModule, clientsModule, devicesModule } from '@/store/modules/store-accessor'
import { TimeUtil } from '@/helpers/TimeUtil'

@Component
export default class ClientsSideviewDevicesTab extends Vue {
 @Prop() client: Record<string, any>;

  formatDate (date: Date): string {
    return TimeUtil.formatDateTime(date)
  }

  get installationsExist() {
    return this.client.installations.length > 0
  }

  get clientDevices() {
    return this.client.devices.filter((item: any) => item.device_id !== null);
  }

  async openDeviceSideview(device: any) {
    devicesModule.setIsLoadingTable(true);
    await devicesModule.getDeviceInfo(device.device_id);
    await installationsModule.getInstallationInfo(device.id);
    devicesModule.setIsLoadingTable(false);
    commonModule.showSideview({ name: 'devices-sideview' , payload: { deviceId: device.device_id, installationId: device.id } })
  }

};
