
import { Component, Vue, Watch } from 'vue-property-decorator'
import config from "../../config";
import { commonModule, statsModule, companyModule } from '@/store/modules/store-accessor'
import KeycloakSession from '@/helpers/keycloak-session'

@Component
export default class Header extends Vue {
  currentLanguage = localStorage.getItem("lang") || "gr";
  languages = ["gr", "en"];

  updateLanguage(language: any) {
      this.currentLanguage = language;
      localStorage.setItem("lang", this.currentLanguage);
      this.$router.go(0); // Reload the whole app/page
    }

  get logo() {
    return require("@/assets/img/new_logo_domX.png")
  }

  get biggLogoShow() {
    if (companyModule.company.email == 'info@domx.io') {
      return true;
    }
    
    return false; 
  }

  get distributorLogoLink() {
    // return companyModule.company.distributor_logo_link;
    if(this.isDistributor) {
      return companyModule.company.company_logo_link;
    }
    else {
      return companyModule.company.distributor_logo_link;
    }
  }

  get kcSession() {
    return new KeycloakSession()
  }

  get isDistributor() {
    return this.kcSession.userHasRole('distributor');
  }

  get isTechnicianCompany () {
    return this.kcSession.userHasRole('technician_company');
  }

  get config() {
    return config;
  }

  get user() {
    return commonModule.user;
  }

  get portalAccess() {
    return commonModule.portalAccess;
  }

  get portalUrl () {
    return (process.env.NODE_ENV === 'production' ? 'PORTAL_URL' : 'https://portal.business.domx-dev.com/')
  }

  logOut() {
    commonModule.showModal({ name: 'log-out-modal' })
  }

  drawerChange() {
    statsModule.changeDrawerState();
  }

  openLink (link: string) {
      window.open(link, '_blank');
  }

}
