<template>
  <v-col cols="4">
    <v-card class="mx-1 mt-1 mb-1">
      <v-card-title class="pt-0">
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="pa-6 pt-0 pb-0">
        <v-row no-gutters>
          <v-col cols="6" class="my-auto">
            <span
              class=""
              :style="{ 'font-size': text_font_size, 'font-weight': 'bold' }"
              >{{ title_number }}
            </span>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="mt-2">
            <span
              class="text-h4 font-weight-light"
              v-text="currentValue.toFixed(1)"
            ></span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-slider
              class="mt-4"
              :step="0.1"
              :min="min"
              :max="max"
              v-model="currentValue"
              :disabled="disabled"
              :color="sliderColor()"
              :track-color="sliderColor()"
              @change="onSliderChange"
            ></v-slider>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
export default {
  props: {
    title_number: {
      type: String,
      required: true,
    },
    text_font_size: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    min: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      currentValue: this.value,
    };
  },
  methods: {
    onSliderChange() {
      this.$emit("valChange", this.currentValue);
    },
    sliderColor() {
      if (this.currentValue < 2.5) return "green";
      if (this.currentValue < 5) return "#a1a819";
      if (this.currentValue < 7) return "orange";
      return "red";
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/app";

.dashboard-page {
  .page-title {
    padding-bottom: 20px;
    @include media-breakpoint-up(sm) {
      padding-bottom: 0;
    }
  }
  .apexcharts-legend-series {
    padding: 2px 5px 3px;
    display: flex;
    align-items: center;
    .apexcharts-legend-marker {
      margin-right: 8px;
    }
  }
  .main-chart-select {
    font-size: 1rem;
    margin-right: 10px;
    fieldset {
      border-width: 1px !important;
      border-color: $text-grey !important;
      color: $text-grey !important;
    }
    .v-select__selection {
      color: $text-color !important;
    }
  }
  .v-card {
    .v-card__text {
      .subtext {
        font-size: 1.142rem;
        font-weight: 400;
        color: $title-grey;
      }
      .subtext-index {
        font-size: 11px;
        color: $text-color;
        font-weight: 400;
      }
    }
  }
}

.v-menu__content {
  box-shadow: $card-shadow !important;
  .v-list-item__content .v-list-item__title {
    font-size: 1rem;
    font-weight: 400;
  }
}
.button-shadow {
  box-shadow: $button-shadow;
}

.v-slider__thumb {
  height: 16px !important;
  width: 16px !important;
}

.v-slider--horizontal .v-slider__track-container {
  height: 5px !important;
}
</style>
