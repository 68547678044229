
import { Component, Vue, Prop } from 'vue-property-decorator'
import { TimeUtil } from '@/helpers/TimeUtil'
import DashboardCardBarChart from "@/components/Cards/DashboardCardBarChart.vue";
import DashboardCardPieChart from "@/components/Cards/DashboardCardPieChart.vue";
import DeviceOverviewCard from "@/components/Cards/DeviceOverviewCard.vue";
import DeviceOverviewSwitchCard from "@/components/Cards/DeviceOverviewSwitchCard.vue";
import DeviceOverviewButtonCard from "@/components/Cards/DeviceOverviewButtonCard.vue";
import DeviceOverviewHeatingBalanceSliderCard from "@/components/Cards/DeviceOverviewHeatingBalanceSliderCard.vue";
import DashboardCardFaultsPieChart from "@/components/Cards/DashboardCardFaultsPieChart.vue";
import DeviceOverviewRoomTargetCard from "@/components/Cards/DeviceOverviewRoomTargetCard.vue";

@Component({
  components: {
    DashboardCardBarChart,
    DashboardCardPieChart,
    DeviceOverviewCard,
    DeviceOverviewSwitchCard,
    DeviceOverviewButtonCard,
    DeviceOverviewHeatingBalanceSliderCard,
    DeviceOverviewRoomTargetCard,
    DashboardCardFaultsPieChart,
  }
})

export default class ThreePhaseEnergyMeterGeneralView extends Vue {
 @Prop() device: Record<string, any>;

  get totalVoltage() {
    let {voltage_a, voltage_b, voltage_c } = this.device.data.properties.voltage
    //If device is installed in a single phase installation then we only need to show the first value
    return (voltage_a)
  }

  get totalPower() {
    let {power_a, power_b, power_c } = this.device.data.properties.power
    return (power_a + power_b + power_c)
  }

  get totalEnergy() {
    let {energy_a, energy_b, energy_c } = this.device.data.properties.energy
    return (energy_a + energy_b + energy_c)
  }

  get totalEnergyDay() {
    let {energy_day_a, energy_day_b, energy_day_c } = this.device.data.properties.energy_day
    return (energy_day_a + energy_day_b + energy_day_c)
  }

  formatDate (date: Date): string {
    return TimeUtil.formatDateTime(date)
  }

};
