import { BaseApi } from '@/helpers/base-api'

export default class DevicesApi extends BaseApi {
  constructor () {
    super('/api/v1/devices')
    this.errorMessage = 'There was an issue when trying to fetch the devices. If this error persists, please contact our support.'
  }

  public getAllDevices = (query: string): Promise<DeviceDto[]> => this.instance.get(`${query}`)
  public getAllDevicesFaultHistory = (): Promise<DeviceDto[]> => this.instance.get(`/faults`)

  public getDeviceDataConsumptionHistory = (query: string): Promise<any> => this.instance.get(`/consumption?${query}`)
  public getDeviceDataClimateHistory = (query: string): Promise<any> => this.instance.get(`/climate?${query}`)

  public getDeviceDataConsumptionTotal = (query: string): Promise<any> => this.instance.get(`/consumption/total?${query}`)
  public getDeviceDataClimateMean = (query: string): Promise<any> => this.instance.get(`/climate/mean?${query}`)

  public getDeviceDataSavingsHistory = (query: string): Promise<any> => this.instance.get(`/savings?${query}`)
  public getDeviceDataSavingsKPI = (query: string): Promise<any> => this.instance.get(`/savings/kpi?${query}`)

  public getAllDevicesById = (clientId: string): Promise<DeviceDto[]> => this.instance.get(`/client/${clientId}`)
  public getDevice = (deviceId: string): Promise<DeviceDto> => this.instance.get(`/${deviceId}`)
  public getDeviceInflux = (deviceId: string, time_from?: number, time_to?: number): Promise<DeviceDto> => this.instance.get(`/${deviceId}/influx`, { params: { time_from: time_from,  time_to: time_to}})

  public getDeviceInfo = (deviceId: string): Promise<DeviceDto> => this.instance.get(`/${deviceId}/info`)

  public checkDeviceId = (data: Record<string, unknown>): Promise<string> => this.instance.post('/checkDeviceId', data)
 
  public getDeviceData = (deviceId: string, days: string, date_from: number, date_to: number, metric: Array<string>): Promise<any> =>  this.instance.get(`/${deviceId}/historical`, { params: { days: days, time_from: date_from,  time_to: date_to, metric: metric} }) 
  public getDeviceMetadata = (deviceId: string, days: string, date_from: number, date_to: number, metric: Array<string>): Promise<any> =>  this.instance.get(`/${deviceId}/metadata`, { params: { days: days, time_from: date_from,  time_to: date_to, metric: metric} }) 
  
  public getDeviceFaultHistory = (deviceId: string, date_from: number, date_to: number): Promise<any> => this.instance.get(`/${deviceId}/historical/faults?time_from=${date_from}&time_to=${date_to}`)
  
  public getDevicesStats = (): Promise<StatsDevicesDto> => this.instance.get(`/stats/installations`)
  
  public deleteDevice = (deviceId: string): Promise<DeviceDto> => this.instance.delete(`/${deviceId}`)
  public getAllDevicesCount = (): Promise<CountDto[]> => this.instance.get('/count')
  
  public createDevice = (data: Record<string, unknown>): Promise<string> => this.instance.post('', data)
  public updateDeviceConfig = (deviceId: string, data: Record<string, unknown>): Promise<string> => this.instance.put(`/${deviceId}/config`, data);
  public updateDeviceInfo = (deviceId: string, data: Record<string, unknown>): Promise<string> => this.instance.patch(`/${deviceId}`, data);

  public getDeviceDataMetrics =  (deviceId: string): Promise<string[]> => this.instance.get(`/${deviceId}/metrics`);
  public getDeviceDataExportGrouped =  (deviceId: string,  data: Record<string, unknown>): Promise<any> => this.instance.get(`/${deviceId}/export/data/grouped`, { params: data });
  public getDeviceDataExportRaw =  (deviceId: string,  data: Record<string, unknown>): Promise<any> => this.instance.get(`/${deviceId}/export/data/raw`, { params: data });
}
