import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators'
import { TimeUtil } from '@/helpers/TimeUtil'
import { companyModule } from '@/store/modules/store-accessor' 
import DistributorApi from '@/api/distributor-api';
import moment from 'moment-timezone';

@Module({ namespaced: true, name: "distributor" })
export default class DistributorModule extends VuexModule {
  public inventory: DeviceDto[] = [];
  public inventoryCount = 0;
  public distributorCompanies: any = [];
  
  public queryObject: any = {};
  public filters = "";

  public isLoadingSideView = true;
  public isLoadingTable = true;
  

  @Action({ commit: "setInventory" })
  async getDevices(query: string): Promise<DeviceDto[]> {
    const distributorApi = new DistributorApi();
    const devices = await distributorApi.getInventory(query);
    return devices;
  }

  @Action({ commit: "setDeviceCount" })
  async getInventoryCount(): Promise<CountDto> {

    const distributorApi = new DistributorApi();
    const devicesCount = await distributorApi.getInventoryCount();
    return devicesCount;
  }

  @Action({ commit: "setDistributorCompanies" })
  async getDistributorCompanies(): Promise<[]> {

    const distributorApi = new DistributorApi();
    const distributorCompanies = await distributorApi.getDistributorCompanies();
    return distributorCompanies;
  }

  @Mutation
  public setDeviceCount(inventoryCount: CountDto): void {
    this.inventoryCount = inventoryCount.count;
  }
  
  @Mutation
  public setDistributorCompanies(distributorCompanies: []): void {
    this.distributorCompanies = distributorCompanies;
    this.isLoadingTable = false;
  }

  @Mutation
  public setInventory (inventory: DeviceDto[]): void {
    try {
      this.isLoadingTable = false;
      inventory.map(item => {
        item.client_name = `${item.client_firstname} ${item.client_lastname}`
        item.readable_installation_date = item.installation_date ? TimeUtil.formatDateTime(item.installation_date) : '-'
      })
      this.inventory = inventory
    } catch (e) {
      this.isLoadingTable = false;
    }
  }

  @Mutation
  public setIsLoadingSideView(isLoadingSideView: boolean): void {
    this.isLoadingSideView = isLoadingSideView
  }

  @Mutation
  public setIsLoadingTable(isLoadingTable: boolean): void {
    this.isLoadingTable = isLoadingTable
  }

  @Mutation
  public setFilters(filters: string): void {
    this.filters = filters
  }

  @Mutation
  public setQueryObject(queryObject: any): void {
    this.queryObject = queryObject
  }

}
