
import { Component, Vue, Prop } from 'vue-property-decorator'
import { TimeUtil } from '@/helpers/TimeUtil'
import HeatingControllerGeneralView from './heating_controller_ot/HeatingControllerGeneralView.vue';
import IAQGeneralView from './iaq/IAQGeneralView.vue';
import MBGeneralView from './heating_controller_mb/MBGeneralView.vue';
import SinglePhaseEnergyMeterGeneralView from './energy_meter/SinglePhaseEnergyMeterGeneralView.vue';
import ThreePhaseEnergyMeterGeneralView from './energy_meter/ThreePhaseEnergyMeterGeneralView.vue';
import { devicesModule, installationsModule } from '@/store/modules/store-accessor';
@Component({
  components: {
    HeatingControllerGeneralView,
    IAQGeneralView,
    MBGeneralView,
    SinglePhaseEnergyMeterGeneralView,
    ThreePhaseEnergyMeterGeneralView
  }
})

export default class DevicesSideviewGeneralTab extends Vue {
 @Prop() device: Record<string, any>;
  formatDate (date: Date): string {
    return TimeUtil.formatDateTime(date)
  }

};
