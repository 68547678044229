
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class FilterList extends Vue {
  @Prop() options!: any[];
  @Prop() label!: string;
  @Prop() text!: string;
  @Prop() multiple!: boolean;
  @Prop({ default: false }) icon!: boolean;
  @Prop({ default: true }) clearable!: boolean;
  @Prop({ default: true }) deletableChips!: boolean;
  @Prop({ default: false }) dense!: boolean;
  @Prop({ default: true }) chips!: boolean;
  @Prop({ default: true }) smallChips!: boolean;
  @Prop({ default: true }) rounded!: boolean;
  @Prop({ default: true }) filled!: boolean;
  @Prop({ default: false }) preSelectFirstItem!: boolean;
  @Prop({ default: false }) selectAll!: boolean;
  @Prop({ default: false }) groupSelected!: boolean;
  @Prop({ default: false }) translate!: boolean;
  @Prop({ default: null }) noDataMessage!: string;
  
  selectedFilters = [] as any;
  allOptionsSelectedFlag = false;

  mounted() {
    if(this.preSelectFirstItem) {
      this.selectedFilters = this.options[0];
    }
  }

  doSelectAll() {
    this.allOptionsSelectedFlag = !this.allOptionsSelectedFlag;
    if (this.allOptionsSelectedFlag) {
      this.selectedFilters = this.options;
    }
    else {
      this.selectedFilters = [];
    }

  }

  @Watch("selectedFilters")
  selectFilter() {
    this.$emit('update-filter', this.selectedFilters);
  }
}
