import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import { VTextField } from "vuetify/lib";
import config from "../config";

Vue.use(Vuetify, {
  theme: {
    // my colors
  },
  components: {
    // This needs to be globally registered for the vuetify-google-autocomplete plugin to work.
    VTextField,
  },
});

export default new Vuetify({
  theme: {
    themes: {
      light: config.light,
      dark: config.light
    },
  },
});
