import store from '@/store/store'
import { getModule } from 'vuex-module-decorators'

import CommonModule from '@/store/modules/common-module'
import ClientsModule from "@/store/modules/clients-module";
import InstallationsModule from "@/store/modules/installations-module";
import DevicesModule from "@/store/modules/devices-module";
import DistributorModule from "@/store/modules/distributor-module";
import StatsModule from "@/store/modules/stats-module";
import MapModule from "@/store/modules/map-module";
import CompanyModule from '@/store/modules/company-module';
import FilterModule from '@/store/modules/filter-module';


export const commonModule = getModule(CommonModule, store)
export const clientsModule = getModule(ClientsModule, store)
export const installationsModule = getModule(InstallationsModule, store)
export const devicesModule = getModule(DevicesModule, store)
export const distributorModule = getModule(DistributorModule, store)
// export const actionsModule = getModule(ActionsModule, store)
// export const paymentsModule = getModule(PaymentsModule, store)
// export const itemsModule = getModule(ItemsModule, store)
// export const appointmentsModule = getModule(AppointmentsModule, store)
export const statsModule = getModule(StatsModule, store)
export const companyModule = getModule(CompanyModule, store)
export const mapModule = getModule(MapModule, store)
// export const jobsModule = getModule(JobsModule, store)
export const filterModule = getModule(FilterModule, store)