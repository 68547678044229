<template>
  <v-container fluid>
    <v-row class="mb-1 ml-5">
      <v-col cols="8" class="pl-0">
        <h1 class="display-1">
          {{ $t("consumption") }}
          <v-btn
            icon
            small
            color="textColor"
            class="refresh"
            @click="refreshData()"
            >
            <v-icon small>mdi-autorenew</v-icon>
          </v-btn>
        </h1>
        <span class="subtitle-2 sub">{{ $t("consumptionSubtitle") }}</span>
        </v-col>
        <v-col cols="4" class="text-right pr-0">
          <v-menu
            ref="dateRangeMenuState"
            v-model="dateRangeMenuState"
            :close-on-content-click="false"

            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="mt-6"
                v-model="dateRangeText"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateRange"
              no-title
              range
              scrollable
              @change="dateRangeUpdated"
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="dateRangeMenuState = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dateRangeMenuState.save(dateRange)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
    </v-row>
    <div class="dashboard-page">
      <v-row no-gutters class="mt-1">
        <v-row justify="space-between">
        <DeviceOverviewCard
            :title="$t('total_consumption')"
            :title_number="totalConsumption"
            text_font_size="30px"
            text_font_weight="normal"
            unit="kwh"
            card_icon="mdi-gas-burner"
            card_icon_color="#3E7DC4"
            card_size=6
        ></DeviceOverviewCard>
        <DeviceOverviewCard
            :title="$t('mean_outdoor_temperature')"
            :title_number="meanClimate"
            text_font_size="30px"
            text_font_weight="normal"
            card_icon="mdi-home"
            unit="celsius"
            card_icon_color="gray"
            card_size=6
        ></DeviceOverviewCard>
          <v-col cols="12" md="12" class="px-1">
            <v-card class="pb-0">
              <v-card-title class="pa-6 pb-0">
                <v-row no-gutters>
                  <v-col
                    cols="7"
                    sm="4"
                    md="4"
                    lg="5"
                    class="d-flex align-center"
                  >
                    <p>{{ $t("consumption_kwh") }}</p>
                  </v-col>
                  <v-col
                    sm="6"
                    md="6"
                    lg="5"
                    class="d-none d-sm-flex align-center"
                  >
                  </v-col>
                </v-row>
              </v-card-title>
                <ApexChart
                  v-if="apexLoading"
                  height="350"
                  type="bar"
                  :options="chartOptions"
                  :series="devicesConsumptionData"
                ></ApexChart>
            </v-card>
          </v-col>
          <v-col cols="12" md="12" class="px-1">
            <v-card class="pb-0">
              <v-card-title class="pa-6 pb-0">
                <v-row no-gutters>
                  <v-col
                    cols="7"
                    sm="4"
                    md="4"
                    lg="5"
                    class="d-flex align-center"
                  >
                    <p>{{ $t("climate") }}</p>
                  </v-col>
                  <v-col
                    sm="6"
                    md="6"
                    lg="5"
                    class="d-none d-sm-flex align-center"
                  >
                  </v-col>
                </v-row>
              </v-card-title>
                <ApexChart
                  v-if="apexLoading"
                  height="350"
                  type="area"
                  :options="chartOptions"
                  :series="devicesClimateData"
                ></ApexChart>
            </v-card>
          </v-col>
        </v-row>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import Vue from "vue";
import { Component } from "vue-property-decorator";
import ApexChart from "vue-apexcharts";
import config from "@/config";
import { commonModule, devicesModule, distributorModule, statsModule, companyModule } from '@/store/modules/store-accessor'
import DashboardTodaysAppointmentsTable from "../components/Tables/DashboardTodaysAppointmentsTable.vue";
import DashboardActiveFaultsTable from "../components/Tables/DashboardActiveFaultsTable.vue";
import DashboardCardBarChart from "../components/Cards/DashboardCardBarChart.vue";
import DashboardCardPieChart from "../components/Cards/DashboardCardPieChart.vue";
import DashboardCardStats from "../components/Cards/DashboardCardStats.vue";
import DeviceOverviewCard from "@/components/Cards/DeviceOverviewCard.vue";
import TheLoadingIndicator from '@/components/TheLoadingIndicator.vue'
import KeycloakSession from '@/helpers/keycloak-session'
import { TimeUtil } from '@/helpers/TimeUtil'
import i18n from "@/i18n";
import dayjs from "dayjs";

@Component({
  components: {
    ApexChart,
    DashboardCardBarChart,
    DashboardCardPieChart,
    DashboardCardStats,
    DeviceOverviewCard,
    DashboardTodaysAppointmentsTable,
    TheLoadingIndicator,
    DashboardActiveFaultsTable,
  },
})
export default class Consumption extends Vue {

  get kcSession() {
    return new KeycloakSession()
  }

  get isDistributor() {
    return this.kcSession.userHasRole('distributor');
  }

  get isTechnicianCompany () {
    return this.kcSession.userHasRole('technician_company');
  }

  get devicesConsumptionData() {
    return [ devicesModule.devicesConsumptionData?.kwh ]
  }

  get devicesClimateData() {
    return [ devicesModule.devicesClimateData?.outdoor_climate ]
  }

  dateRange = [dayjs().startOf('week').format('YYYY-MM-DD'), dayjs().endOf('day').format('YYYY-MM-DD')];

  dateRangeUpdated(test) {
    let sorted = test.sort((a,b) => {
      var dateA = dayjs(a).unix(),
          dateB = dayjs(b).unix();
      if (dateB > dateA) {
        return -1;
      } else {
        return 1;
      }
    })
    let dateFrom = dayjs(sorted[0]).startOf('day').toISOString();
    let dateTo = dayjs(sorted[1]).endOf('day').toISOString();

    devicesModule.getDeviceConsumptionDataSeries({date_from: dateFrom, date_to: dateTo});
    devicesModule.getDeviceClimateDataSeries({date_from: dateFrom, date_to: dateTo});
    devicesModule.getDeviceTotalConsumption({date_from: dateFrom, date_to: dateTo});
    devicesModule.getDeviceMeanClimate({date_from: dateFrom, date_to: dateTo});
  }

  select = [
    this.$i18n.t("weekly"),
    this.$i18n.t("monthly"),
    this.$i18n.t("yearly"),
  ];

  apexLoading = false;
  dateRangeMenuState = false;
  actionsSelect = this.$i18n.t("weekly");

  chartOptions = {
    chart: {
      height: 380,
      width: "100%",
      type: "area",
      stacked: true,
      animations: {
        initialAnimation: {
          enabled: false
        }
      }
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      formatter: function(seriesName, opts) {

        return [i18n.t(seriesName)]
      }
    },
    yaxis: {
      decimalsInFloat: 1
    },
    xaxis: {
      type: 'datetime',
      labels: {
        datetimeUTC: false
      }
    },
    tooltip: {
      y: {
        formatter: (val) => {
         if(val) {
           return val.toFixed(1)
         }
        }
      },
      x: {
        formatter: (val) => {
          if(val) {
           return TimeUtil.formatInfluxDateTime(val) 
          }
        }
      }
    }
  };


  get totalConsumption() {
    return devicesModule.devicesConsumptionTotalData.kwh?.toFixed(1);
  }

  get meanClimate() {
    return devicesModule.devicesClimateMeanData.outdoor_climate?.toFixed(1);
  }

  get dateRangeText () {
    this.dateRange.map((item) => dayjs(item).format('DD/MM/YYYY'))


    let sorted = this.dateRange.sort((a,b) => {
      var dateA = dayjs(a).unix(),
          dateB = dayjs(b).unix();
      if (dateB > dateA) {
        return -1;
      } else {
        return 1;
      }
    })

    return sorted.join(' - ')
  }

  async refreshData() {
    let dateFrom = dayjs(this.dateRange[0]).startOf('day').toISOString();
    let dateTo = dayjs(this.dateRange[1]).endOf('day').toISOString();

    devicesModule.getDeviceConsumptionDataSeries({date_from: dateFrom, date_to: dateTo});
    devicesModule.getDeviceClimateDataSeries({date_from: dateFrom, date_to: dateTo});

    devicesModule.getDeviceTotalConsumption({date_from: dateFrom, date_to: dateTo});
    devicesModule.getDeviceMeanClimate({date_from: dateFrom, date_to: dateTo});

  }

  async mounted () {
    setTimeout(() => {
      this.apexLoading = true;
    });

    let dateFrom = dayjs(this.dateRange[0]).startOf('day').toISOString();
    let dateTo = dayjs(this.dateRange[1]).endOf('day').toISOString();

    devicesModule.getDeviceConsumptionDataSeries({date_from: dateFrom, date_to: dateTo});
    devicesModule.getDeviceClimateDataSeries({date_from: dateFrom, date_to: dateTo});
    devicesModule.getDeviceTotalConsumption({date_from: dateFrom, date_to: dateTo});
    devicesModule.getDeviceMeanClimate({date_from: dateFrom, date_to: dateTo});

    companyModule.getCompanyInfo();

    devicesModule.getDeviceCount();
    devicesModule.getDevicesActiveFaults();
    devicesModule.getDevices('');
    
    if(this.isDistributor) {
      distributorModule.getInventoryCount();
    }
    else {
      devicesModule.getDeviceCount();
    }
    // distributorModule.getDevices('');
    commonModule.initSnackbar({});
    /*
     TODO: Handle installations properly when UX is clear.
     */
    statsModule.getStatsAction();
    await statsModule.getStatsLastMonthsAction();
    statsModule.SetisLoadingLastMonths(false);
    await statsModule.getStatsActionsAction();
    await statsModule.getStatsDevicesAction();
    statsModule.SetisLoadingActionStats(false);

  }
}
</script>

<style lang="scss">
@import "../styles/app";

.dashboard-page {
  .page-title {
    padding-bottom: 20px;
    @include media-breakpoint-up(sm) {
      padding-bottom: 0;
    }
  }
  .apexcharts-legend-series {
    padding: 2px 5px 3px;
    display: flex;
    align-items: center;
    .apexcharts-legend-marker {
      margin-right: 8px;
    }
  }
  .main-chart-select {
    font-size: 1rem;
    margin-right: 10px;
    max-width: 300px;
    fieldset {
      border-width: 1px !important;
      border-color: $text-grey !important;
      color: $text-grey !important;
    }
    .v-select__selection {
      color: $text-color !important;
    }
  }
  .v-card {
    .v-card__text {
      .subtext {
        font-size: 1.142rem;
        font-weight: 400;
        color: $title-grey;
      }
      .subtext-index {
        font-size: 11px;
        color: $text-color;
        font-weight: 400;
      }
    }
  }
}

.v-menu__content {
  box-shadow: $card-shadow !important;
  .v-list-item__content .v-list-item__title {
    font-size: 1rem;
    font-weight: 400;
  }
}
.button-shadow {
  box-shadow: $button-shadow;
}

.dashboard {
  position: relative;
}

h1 {
  margin-top: 40px;
  font-size: 35px;
  font-weight: 500;
  line-height: 65px;
}

.sub {
  font-weight: normal!important;
}

.dashboard-main-select {
  width: 450px;
 float: right;
}
</style>
