import { BaseApi } from '@/helpers/base-api'

export default class FilterApi extends BaseApi {
  constructor () {
    super('/api/v1/filters')
    this.errorMessage = 'There was an issue when trying to fetch the filters information. If this error persists, please contact our support.'

  }

  public getItemFilters = (): Promise<ItemFilterDto[]> => this.instance.get('/items')
  public getInstallationsFilters = (): Promise<InstallationsFilterDto[]> => this.instance.get('/installations')
  public getDevicesFilters = (): Promise<DevicesFilterDto[]> => this.instance.get('/devices')
  public getInventoryFilters = (): Promise<DevicesFilterDto[]> => this.instance.get('/inventory')
  public getIsPremiumFilters = (): Promise<IsPremiumFilterDto[]> => this.instance.get('/is_premium')
}