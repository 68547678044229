import { BaseApi } from '@/helpers/base-api'

export default class PaymentsApi extends BaseApi {
  constructor () {
    super('/api/v1/payments')
    this.errorMessage = 'There was an issue when trying to fetch the payments. If this error persists, please contact our support.'
  }

  public getAllPayments = (query: string): Promise<PaymentDto[]> => this.instance.get(`${query}`)
  
  public createPayments = (data: Record<string, unknown>): Promise<JSON> => this.instance.post('', data)

  public updatePayments = (paymentId: string, data: Record<string, unknown>): Promise<JSON> => this.instance.patch(`/${paymentId}`, data)

  public deletePayments = (paymentId: string): Promise<JSON> => this.instance.delete(`/${paymentId}`)

}
