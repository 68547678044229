
import axios, { AxiosRequestConfig, AxiosInstance, AxiosResponse, AxiosError } from 'axios'
import KeycloakSession from '@/helpers/keycloak-session'
import { commonModule } from '@/store/modules/store-accessor'
import {statusCodeMsg} from './data'

export abstract class BaseApi {
  protected readonly instance: AxiosInstance;
  private kcSession: KeycloakSession;
  public errorMessage!: string

  public constructor (baseURL: string) {
    let url = 'https://api.control.business.domx-dev.com' + baseURL;
    
    if (process.env.NODE_ENV === "production") {
      axios.defaults.baseURL = "API_BASE_URL";
      url = 'API_BASE_URL' + baseURL;
    }

    const axiosConfig: AxiosRequestConfig = {
      baseURL: url
    }
    
    this.instance = axios.create(axiosConfig)
    this.instance.defaults.timeout = 1000 * 30 // 30 seconds for now
    this.kcSession = new KeycloakSession()
    this.initResponseRequestInterceptor()
  }

  private initResponseRequestInterceptor = () => {
    this.instance.interceptors.response.use(
      this.handleResponse,
      this.handleError
    )
    this.instance.interceptors.request.use(this.handleRequest)
  }

  private handleResponse = ({ data, status }: AxiosResponse) => {

    if (status.toString().charAt(0) === '4' || status.toString().charAt(0) === '5') {
      throw new Error(`Server returned statuscode ${status}`)
    }

    return data
  };

  protected handleError = (error: AxiosError): void => {
    if (error.response?.status.toString().charAt(0) === '4' || error.response?.status.toString().charAt(0) === '5') {
      commonModule.initSnackbar({ visible: true, color: "error", timeout: 2000, text: statusCodeMsg(error.response?.status.toString()) });
    }

    // eslint-disable-next-line no-console
    console.warn('REST call responded with error:', error)
    const el = document.getElementById('vue-wrapper')
    if (!el) return
    const event = new CustomEvent('vueErrorMessage', {
      bubbles: true,
      cancelable: false,
      composed: true,
      detail: this.errorMessage
    })
    el.dispatchEvent(event)
  }

  private handleRequest = async (config: AxiosRequestConfig) => {
    const token = await this.kcSession.updateToken()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    config.headers['app-tracing-id'] = (window as any).vueAppTracingId
    config.headers.Authorization = `Bearer ${token}`
    return config
  }
}
