
import { latLng, Icon } from "leaflet";
import { LMap, LTileLayer, LMarker, LPopup, LIcon } from "vue2-leaflet";
import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster";
import { Component, Vue, Watch } from 'vue-property-decorator'
import "../styles/MarkerCluster.css";
import "../styles/MarkerCluster.Default.css";
import TheLoadingIndicator from '@/components/TheLoadingIndicator.vue'

import { commonModule, mapModule, filterModule, installationsModule, devicesModule } from '@/store/modules/store-accessor'
import KeycloakSession from '@/helpers/keycloak-session'

Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

@Component({
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LIcon,
    "v-marker-cluster": Vue2LeafletMarkerCluster,
    TheLoadingIndicator,
  }
})

export default class Map extends Vue {
      staticAnchor = [16, 37]
      zoom = 7
      center = latLng(39.638902, 22.946519)
      url = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      attribution =
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      withPopup = latLng(39.638902, 22.946519)
      currentZoom = 8
      currentCenter = latLng(39.638902, 22.946519)
      showParagraph = false
      mapOptions = {
        zoomSnap: 0.5,
      }
      showMap = true

  get kcSession() {
    return new KeycloakSession()
  }

  get isDistributor() {
    return this.kcSession.userHasRole('distributor');
  }

  get isTechnicianCompany () {
    return this.kcSession.userHasRole('technician_company');
  }

  get markers() {
    return mapModule.markers
  }

  get markersLength() {
    return this.markers.length
  }

  get isLoading() {
    return mapModule.isLoading;
  }

  async selectInstallation(device: any) {
    devicesModule.setIsLoadingTable(true);
    await devicesModule.getDeviceInfo(device.device_id);
    devicesModule.setIsLoadingTable(false);
    if(this.isTechnicianCompany) {

      await installationsModule.getInstallationInfo(device.installation_id);
      commonModule.showSideview({ name: 'devices-sideview' , payload: { installationId: device.installation_id, deviceId: device.device_id} })
    }
    else if(this.isDistributor) {
      commonModule.showSideview({ name: 'distributor-device-sideview' , payload: { installationId: device.installation_id, deviceId: device.device_id} })
    }
  }

  async refreshData() {
    mapModule.getMarkersAction('');
  }

  async mounted () {
    mapModule.getMarkersAction('');
    commonModule.initSnackbar({});
  }
}
