
import { Component, Vue, Watch } from 'vue-property-decorator'
import { commonModule, installationsModule, filterModule, devicesModule } from '@/store/modules/store-accessor'
import Dropdown from '../components/dropdown/Dropdown.vue'
import DropdownItem from '../components/dropdown/DropdownItem.vue'
import DevicesFilter from '@/components/Filters/DevicesFilter.vue'

import { TimeUtil } from '@/helpers/TimeUtil'
import {getIconFromDeviceType} from '@/helpers/data'

@Component({
  components: { Dropdown, DropdownItem, DevicesFilter }
})
export default class Devices extends Vue {
  tableheaders: any [] = [
    { text: this.$t("type"), align: 'left', sortable: true, class: 'row-style', value: 'type' },
    { text: this.$t("serial_number"), align: 'left', class: 'row-style', sortable: true, value: 'serial_no' },
    { text: this.$t("device_id"), align: 'left', class: 'row-style', sortable: true, value: 'device_id' },
    { text: this.$t("device_friendly_name"), align: 'left', class: 'row-style', sortable: true, value: 'device_name' },
    { text: this.$t("fullname"), sortable: true, class: 'row-style', value: 'client_name' },
    { text: this.$t("installation"), sortable: true, class: 'row-style', value: 'address' },
    { text: this.$t("notes"), sortable: true, class: 'row-style', value: 'notes' },
    { text: this.$t("online"), sortable: true, class: 'row-style', value: 'onlineState' },
    { text: this.$t("device_health"), sortable: true, class: 'row-style', value: 'fault' },
    // { text: this.$t("last_update"), sortable: true, class: 'row-style', value: 'last_update' },
    { text: '', align: 'right', sortable: false, class: 'row-style', value: 'id', width: '10px'  } 
  ]

  model: any = {
    search: '',
    selected: [] as string[]
  }

  formatDate (date: Date): string {
    return TimeUtil.formatDateTime(date)
  }

  getIconFromDeviceType(deviceType: string) {
    return getIconFromDeviceType(deviceType);
  }

  get devicesLength() {
    return devicesModule.deviceCount
  }

  get searchLabel() {
    return this.$t("devicesSearch")
  }

  get devices(){
    return devicesModule.devices
  }

  get currInstallation(){
    return installationsModule.currentInstallation
  }

  get isLoadingTable() {
    return devicesModule.isLoadingTable;
  }

  async refreshData() {
    devicesModule.getDeviceCount();
    devicesModule.getDevices(devicesModule.filters);
    filterModule.getDevicesFilters();
  }

  async mounted () {
    /*
     TODO: Handle installations properly when UX is clear.
     */
    devicesModule.getDeviceCount();
    devicesModule.getDevices(devicesModule.filters);
    filterModule.getDevicesFilters();
    commonModule.getClientsCombo();
    commonModule.getPaymentTypeCombo();
    commonModule.initSnackbar({});
  }

  async destroyed () {
    devicesModule.clearDevices();
  }

  async selectDevice (device: any) {
    devicesModule.setIsLoadingTable(true);

    await devicesModule.getDeviceInfo(device.device_id);
    await installationsModule.getInstallationInfo(device.installation_id);
    devicesModule.setIsLoadingTable(false);
    commonModule.showSideview({ name: 'devices-sideview' , payload: { installationId: device.installation_id, deviceId: device.device_id} })
  }

  async addDevice (){
    await installationsModule.getInstallationNextTagId();
    commonModule.showModal({ name: 'add-device-modal' })
  }

  deleteDevice(device: any) {
    commonModule.showModal({ name: 'delete-device-modal', payload: { device: device}})
  }

  async editDevice (device: any) {
    await devicesModule.getDeviceInfo(device.device_id);
    await installationsModule.getInstallationInfo(device.installation_id);
    commonModule.showModal({ name: 'edit-device-modal', payload: { view: "installations"} })
  }
}
