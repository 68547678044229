<template>
  <v-container fluid>
    <v-row class="mb-1">
      <v-col cols="6" class="pl-0">
          <v-btn
            icon
            small
            color="textColor"
            class="refresh"
            @click="refreshData()"
            >
            <v-icon small>mdi-autorenew</v-icon>
          </v-btn>
        </v-col>
    </v-row>
    <div class="dashboard-page">
      <v-row class="mt-1">
        <v-row justify="space-between">
          <DashboardCardStats
            v-if="isDistributor"
            :title="$t('total_devices')"
            :title_number="totalDeviceCount"
            text_font_size="42px"
            card_icon="mdi-list-box-outline"
            card_icon_color="gray"
          ></DashboardCardStats>
          <DashboardCardStats
            :title="$t('installed_devices')"
            :title_number="installedDeviceCount"
            text_font_size="42px"
            card_icon="mdi-account-hard-hat"
            card_icon_color="blue"
          ></DashboardCardStats>
          <DashboardCardStats
            :title="$t('active_devices')"
            :title_number="devicesOnline"
            text_font_size="42px"
            card_icon="mdi-home-automation"
            card_icon_color="green"
          ></DashboardCardStats>
          <DashboardCardStats
            :title="$t('devices_with_error')"
            :title_number="devicesWithFaults"
            text_font_size="42px"
            card_icon="mdi-alert-outline"
            card_icon_color="orange"
          ></DashboardCardStats>
          <DashboardCardPieChart v-if="isTechnicianCompany" :title="$t('faults_breakdown')"
            :chart_series="devicesWithActiveFaultsChart" :isLoading="isLoadingStats"></DashboardCardPieChart>
          <v-col cols="12" md="6">
            <v-card class="pb-1">
              <v-card-title class="pa-6 pb-0">
                <v-row no-gutters>
                  <v-col cols="8" class="d-flex align-center">
                    <span class="textColor2--text">{{ $t("device_installations") }}</span>
                  </v-col>
                  <v-col cols="4">
                    <v-menu>
                      <template v-slot:activator="{ on, attrs }">
                        <v-select class="main-chart-select" v-model="actionsSelect" v-bind="attrs" v-on="on" dense flat
                          single-line hide-details :items="select" outlined></v-select>
                      </template>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text class="pa-6">
                <v-row>
                  <v-col>
                    <v-sheet class="dashboard">
                      <ApexChart v-if="apexLoading" height="450" type="line" :options="
                        actionsSelect === $t('weekly')
                          ? actionsApexArea.weekly_options
                          : actionsSelect === $t('monthly')
                          ? actionsApexArea.monthly_options
                          : actionsApexArea.yearly_options
                      " :series="
                      actionsSelect === $t('weekly')
                        ? actionsApexArea.weekly
                        : actionsSelect === $t('monthly')
                        ? actionsApexArea.monthly
                        : actionsApexArea.yearly
                    "></ApexChart>
                      <TheLoadingIndicator :loading="isLoadingActionStats" />
                    </v-sheet>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card class="pb-0">
              <v-card-title class="pa-6 pb-0">
                <span class="textColor2--text">{{ $t("faults") }}</span>
                <v-spacer></v-spacer>
              </v-card-title>
              <DashboardActiveFaultsTable
                :items="devicesWithActiveFaults" />
            </v-card>
          </v-col>
        </v-row>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import Vue from "vue";
import { Component } from "vue-property-decorator";
import ApexChart from "vue-apexcharts";
import config from "@/config";
import { commonModule, devicesModule, distributorModule, statsModule, companyModule } from '@/store/modules/store-accessor'
import DashboardTodaysAppointmentsTable from "../components/Tables/DashboardTodaysAppointmentsTable.vue";
import DashboardActiveFaultsTable from "../components/Tables/DashboardActiveFaultsTable.vue";
import DashboardCardBarChart from "../components/Cards/DashboardCardBarChart.vue";
import DashboardCardPieChart from "../components/Cards/DashboardCardPieChart.vue";
import DashboardCardStats from "../components/Cards/DashboardCardStats.vue";
import TheLoadingIndicator from '@/components/TheLoadingIndicator.vue'
import KeycloakSession from '@/helpers/keycloak-session'
import { TimeUtil } from '@/helpers/TimeUtil'
import i18n from "@/i18n";


@Component({
  components: {
    ApexChart,
    DashboardCardBarChart,
    DashboardCardPieChart,
    DashboardCardStats,
    DashboardTodaysAppointmentsTable,
    TheLoadingIndicator,
    DashboardActiveFaultsTable,
  },
})
export default class Dashboard extends Vue {

  get kcSession() {
    return new KeycloakSession()
  }

  get isDistributor() {
    return this.kcSession.userHasRole('distributor');
  }

  get isTechnicianCompany () {
    return this.kcSession.userHasRole('technician_company');
  }

  get devicesConsumptionData() {
    return [ devicesModule.devicesConsumptionData.kwh ]
  }

  get devicesClimateData() {
    return [ devicesModule.devicesClimateData.outdoor_climate, devicesModule.devicesClimateData.indoor_climate, devicesModule.devicesClimateData.indoor_climate_set ]
  }

  get chartActive() {
    // return companyModule.company.distributor_logo_link;
    if(this.isDistributor) {
       if (companyModule.company.email == 'info@domx.io') {
        return true;
       }
    }

    return false;
  }

  selectTimeRange = [
    { query: {hours: '24'}, text: this.$i18n.t("hourly_data") },
    { query: {days: '2'}, text: this.$i18n.t("daily_data") },
    { query: {days: '7'}, text: this.$i18n.t("weekly_data") }, 
    { query: {days: '30'}, text: this.$i18n.t("monthly_data") }
  ]

  consumptionApexAreaSelect = this.selectTimeRange[0];
  climateApexAreaSelect = this.selectTimeRange[0];

  async changeConsumptionDataTimeRange(selection) {
    const arrayItem = this.selectTimeRange.find(o => { return o.query === selection}) ;
    
    if(arrayItem != undefined) {
      this.roomTemperaturesApexAreaSelect = arrayItem
    }

  }

  appointments_search = "";
  appointments_headers = [
    {
      text: this.$i18n.t("client"),
      align: "start",
      value: "client_name",
    },
    { text: this.$i18n.t("type"), value: "type_name" },
    { text: this.$i18n.t("user"), value: "assignee_id" },
    { text: this.$i18n.t("address"), value: "address" },
    { text: this.$i18n.t("area"), value: "area" },
    { text: this.$i18n.t("scheduled_at"), value: "scheduled_start" },
    { text: this.$i18n.t("projected_duration"), value: "projected_duration" },
    { text: this.$i18n.t("status"), value: "appointment_status_id" },
  ];

  select = [
    this.$i18n.t("weekly"),
    this.$i18n.t("monthly"),
    this.$i18n.t("yearly"),
  ];
  actionsApexArea = {
    weekly_options: {
      chart: {
        width: "100%",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      markers: {
        size: [5],
      },
      stroke: {
        width: [3],
        curve: ["smooth"],
      },
      fill: {
        type: "linear",
      },
      legend: {
        show: false,
      },
      colors: [config.light.error],
      yaxis: {
        axisBorder: {
          show: true,
          color: "#B9B9B980",
        },
        labels: {
          formatter: function (val) {
            return val.toFixed(0);
          },
          style: {
            fontSize: "12px",
          },
        },
      },
      xaxis: {
        categories: [],
        labels: {
          style: {
            fontSize: "12px",
          },
        },
        tickAmount: 10,
      },
    },
    monthly_options: {
      chart: {
        width: "100%",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      markers: {
        size: [5],
      },
      stroke: {
        width: [3],
        curve: ["smooth"],
      },
      fill: {
        type: "linear",
      },
      legend: {
        show: false,
      },
      colors: [config.light.error],
      yaxis: {
        axisBorder: {
          show: true,
          color: "#B9B9B980",
        },
        labels: {
          formatter: function (val) {
            return val.toFixed(0);
          },
          style: {
            fontSize: "12px",
          },
        },
      },
      xaxis: {
        categories: [],
        labels: {
          style: {
            fontSize: "12px",
          },
        },
        tickAmount: 10,
      },
    },
    yearly_options: {
      chart: {
        width: "100%",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      markers: {
        size: [5],
      },
      stroke: {
        width: [3],
        curve: ["smooth"],
      },
      fill: {
        type: "linear",
      },
      legend: {
        show: false,
      },
      colors: [config.light.error],
      yaxis: {
        axisBorder: {
          show: true,
          color: "#B9B9B980",
        },
        labels: {
          formatter: function (val) {
            return val.toFixed(0);
          },
          style: {
            fontSize: "12px",
          },
        },
        tickAmount: 5,
      },
      xaxis: {
        categories: [],
        labels: {
          style: {
            fontSize: "12px",
          },
        },
        tickAmount: 10,
      },
    },
    weekly: [
      {
        name: this.$i18n.t("installations"),
        type: "line",
        data: [],
      },
    ],
    monthly: [
      {
        name: this.$i18n.t("installations"),
        type: "line",
        data: [],
      },
    ],
    yearly: [
      {
        name: this.$i18n.t("installations"),
        type: "line",
        data: [],
      },
    ],
  };
  clientsBarChart = {
    options: {
      chart: {
        id: "clients",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",
          distributed: true,
          endingShape: "rounded",
          startingShape: "rounded",
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      stroke: {
        width: 3,
        curve: ["smooth"],
      },
      colors: [config.light.primary],
      dataLabels: {
        enabled: false,
        dropShadow: {
          enable: false,
        },
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: [],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return val.toFixed(0);
          },
          show: false,
        },
      },
    },
    series: [
      {
        name: this.$i18n.t("new_clients"),
        data: [],
      },
    ],
  };
  installationsBarChart = {
    options: {
      chart: {
        id: "installations",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",
          distributed: true,
          endingShape: "rounded",
          startingShape: "rounded",
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      stroke: {
        width: 3,
        curve: ["smooth"],
      },
      colors: [config.light.info],
      dataLabels: {
        enabled: false,
        dropShadow: {
          enable: false,
        },
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: [],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return val.toFixed(0);
          },
          show: false,
        },
      },
    },
    series: [
      {
        name: this.$i18n.t("new_installations"),
        data: [],
      },
    ],
  };
  jobsBarChart = {
    options: {
      chart: {
        id: "jobs",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",
          distributed: true,
          endingShape: "rounded",
          startingShape: "rounded",
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      stroke: {
        width: 3,
        curve: ["smooth"],
      },
      colors: [config.light.error],
      dataLabels: {
        enabled: false,
        dropShadow: {
          enable: false,
        },
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: [],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return val.toFixed(0);
          },
          show: false,
        },
      },
    },
    series: [
      {
        name: this.$i18n.t("jobs"),
        data: [],
      },
    ],
  };
  actionsBreakdownPie = {
    options: {
          series: this.devicesWithActiveFaultsChart,
          chart: {
          type: 'donut',
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      }
  };
  apexLoading = false;
  actionsSelect = this.$i18n.t("weekly");

  chartOptions = {
    chart: {
      height: 380,
      width: "100%",
      type: "area",
      animations: {
        initialAnimation: {
          enabled: false
        }
      }
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      formatter: function(seriesName, opts) {

        return [i18n.t(seriesName)]
      }
    },
    yaxis: {
      decimalsInFloat: 1
    },
    xaxis: {
      type: 'datetime'
    },
    tooltip: {
      y: {
        formatter: (val) => {
         if(val) {
           return val.toFixed(1)
         }
        }
      },
      x: {
        formatter: (val) => {
          if(val) {
           return TimeUtil.formatInfluxDateTime(val) 
          }
        }
      }
    }
  };

  get totalDeviceCount() {
    if(this.isDistributor) {
      distributorModule.inventoryCount;
    }
    else {
      devicesModule.deviceCount;
    }
    return distributorModule.inventoryCount;
  }

  get installedDeviceCount() {
    return devicesModule.deviceCount;
  }

  get devicesWithFaults(){
    return devicesModule.devicesFaultCount;
  }

  get devicesWithActiveFaults(){
    return devicesModule.deviceActiveFaults;
  }

  get devicesWithActiveFaultsChart(){
    return devicesModule.deviceActiveFaultsChart;
  }

  get devicesOnline(){
    return devicesModule.devicesOnlineCount;
  }

  get stats(){
    return statsModule.stats
  }

  get lastMonths(){
    return statsModule.lastMonths
  }

  get statsActions(){
    return statsModule.statsActions
  }

  get statsDevices() {
    return statsModule.statsDevices
  }

  get isLoadingStats() {
    return statsModule.isLoadingStats;
  }

  get isLoadingActionStats() {
    return statsModule.isLoadingActionStats;
  }

  get isLoadingTodaysAppointmentTable() {
    return statsModule.isLoadingTodaysAppointmentsTable;
  }

  get isLoadingLastMonths() {
    return statsModule.isLoadingLastMonths;
  }

  async refreshData() {
    companyModule.getCompanyInfo();

    devicesModule.getDeviceCount();
    devicesModule.getDevicesActiveFaults();
    devicesModule.getDevices('');

    statsModule.getStatsAction();
    await statsModule.getStatsLastMonthsAction();
    statsModule.SetisLoadingLastMonths(false);
    await statsModule.getStatsActionsAction();
    await statsModule.getStatsDevicesAction();
    statsModule.SetisLoadingActionStats(false);

  }

  async mounted () {
    setTimeout(() => {
      this.apexLoading = true;
    });

    companyModule.getCompanyInfo();

    devicesModule.getDeviceCount();
    devicesModule.getDevicesActiveFaults();
    devicesModule.getDevices('');
    
    if(this.isDistributor) {
      distributorModule.getInventoryCount();
    }
    else {
      devicesModule.getDeviceCount();
    }
    // distributorModule.getDevices('');
    commonModule.initSnackbar({});
    /*
     TODO: Handle installations properly when UX is clear.
     */
    statsModule.getStatsAction();
    await statsModule.getStatsLastMonthsAction();
    statsModule.SetisLoadingLastMonths(false);
    await statsModule.getStatsActionsAction();
    await statsModule.getStatsDevicesAction();
    statsModule.SetisLoadingActionStats(false);

    this.clientsBarChart.options.xaxis.categories = [];
    this.clientsBarChart.series[0].data = [];
    this.installationsBarChart.options.xaxis.categories = [];
    this.installationsBarChart.series[0].data = [];
    this.jobsBarChart.options.xaxis.categories = [];
    this.jobsBarChart.series[0].data = [];

    const installationsBarChartDates = [];
    for (
      let i = 0;
      i < this.lastMonths.installations_last_months.length;
      i++
    ) {
      installationsBarChartDates.push(
        this.lastMonths.installations_last_months[i].date
      );
      this.installationsBarChart.series[0].data.push(
        this.lastMonths.installations_last_months[i].count
      );
    }
    this.installationsBarChart.options = {
      ...this.installationsBarChart.options,
      ...{
        xaxis: {
          categories: installationsBarChartDates,
        },
      },
    };

    let data_series = [];
    let data_labels = [];
    let data_colors = [];

    this.actionsApexArea.weekly_options.xaxis.categories = [];
    this.actionsApexArea.weekly[0].data = [];
    this.actionsApexArea.monthly_options.xaxis.categories = [];
    this.actionsApexArea.monthly[0].data = [];
    this.actionsApexArea.yearly_options.xaxis.categories = [];
    this.actionsApexArea.yearly[0].data = [];
    const weekly_dates = [];
    for (let i = 0; i < this.statsDevices.devices_weekly.length; i++) {
      weekly_dates.push(this.statsDevices.devices_weekly[i].date);
      this.actionsApexArea.weekly[0].data.push(
        this.statsDevices.devices_weekly[i].count
      );
    }

    this.actionsApexArea.weekly_options = {
      ...this.actionsApexArea.weekly_options,
      ...{
        xaxis: {
          categories: weekly_dates,
        },
      },
    };

    for (let i = 0; i < this.statsDevices.devices_monthly.length; i++) {
      this.actionsApexArea.monthly_options.xaxis.categories.push(
        this.statsDevices.devices_monthly[i].date
      );
      this.actionsApexArea.monthly[0].data.push(
        this.statsDevices.devices_monthly[i].count
      );
    }
    for (let i = 0; i < this.statsDevices.devices_yearly.length; i++) {
      this.actionsApexArea.yearly_options.xaxis.categories.push(
        this.statsDevices.devices_yearly[i].date
      );
      this.actionsApexArea.yearly[0].data.push(
        this.statsDevices.devices_yearly[i].count
      );
    }

    this.actionsBreakdownPie.options = {
      ...this.actionsBreakdownPie.options,
      ...{
        colors: data_colors,
        labels: data_labels,
        series: data_series,
      },
    };
  }
}
</script>

<style lang="scss">
@import "../styles/app";

.dashboard-page {
  .page-title {
    padding-bottom: 20px;
    @include media-breakpoint-up(sm) {
      padding-bottom: 0;
    }
  }
  .apexcharts-legend-series {
    padding: 2px 5px 3px;
    display: flex;
    align-items: center;
    .apexcharts-legend-marker {
      margin-right: 8px;
    }
  }
  .main-chart-select {
    font-size: 1rem;
    margin-right: 10px;
    max-width: 300px;
    fieldset {
      border-width: 1px !important;
      border-color: $text-grey !important;
      color: $text-grey !important;
    }
    .v-select__selection {
      color: $text-color !important;
    }
  }
  .v-card {
    .v-card__text {
      .subtext {
        font-size: 1.142rem;
        font-weight: 400;
        color: $title-grey;
      }
      .subtext-index {
        font-size: 11px;
        color: $text-color;
        font-weight: 400;
      }
    }
  }
}

.v-menu__content {
  box-shadow: $card-shadow !important;
  .v-list-item__content .v-list-item__title {
    font-size: 1rem;
    font-weight: 400;
  }
}
.button-shadow {
  box-shadow: $button-shadow;
}

.dashboard {
  position: relative;
}
</style>
