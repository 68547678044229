import { BaseApi } from '@/helpers/base-api'

export default class JobsApi extends BaseApi {
  constructor () {
    super('/api/v1/jobs')
    this.errorMessage = 'There was an issue when trying to fetch the jobs. If this error persists, please contact our support.'
  }

  public getAllJobs = (): Promise<JobDto[]> => this.instance.get('')
  public getJob = (jobId: string): Promise<JobDto> => this.instance.get(`/${jobId}`)
  
  public createJob = (data: Record<string, any>): Promise<JSON> => this.instance.post('', data)

  public createJobWithActions = (data: Record<string, any>): Promise<JSON> => this.instance.post('/withactions', data)

  public updatejob = (jobId: string, data: Record<string, unknown>): Promise<JSON> => this.instance.patch(`/${jobId}`, data)

  public createJobAppointment = (jobId: string, data: Record<string, unknown>): Promise<JSON> => this.instance.post(`/${jobId}/appointments`, data)

  public createJobActions = (jobId: string, data: Record<string, unknown>): Promise<JSON> => this.instance.post(`/${jobId}/actions`, data)

  public createJobPayment = (jobId: string, data: Record<string, unknown>): Promise<JSON> => this.instance.post(`/${jobId}/payments`, data)

  public deleteJob = (jobId: string): Promise<JSON> => this.instance.delete(`/${jobId}`)

}
