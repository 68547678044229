
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { TimeUtil } from '@/helpers/TimeUtil'
import { devicesModule } from '@/store/modules/store-accessor'

@Component
export default class DevicesSideviewFaultsTab extends Vue {
 @Prop() device: Record<string, any>;

  tableheaders: any [] = [
    {
      text: this.$t("date"),
      align: 'left',
      class: 'row-style',
      sortable: true,
      value: 'time'
    },
    { text: this.$t("fault"), sortable: true, class: 'row-style', value: 'oem' },
  ]

  deviceFaultData = []

  async mounted() {
      this.deviceFaultData = await devicesModule.getDeviceFaultHistory(this.device.info.device_id);
  }
  
  formatDate (date: string): string {
    return TimeUtil.formatInfluxDate(date)
  }
};
