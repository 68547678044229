
  import { Component, Vue, Prop } from 'vue-property-decorator'
  import { commonModule, installationsModule, companyModule, statsModule, clientsModule, filterModule } from '@/store/modules/store-accessor'
  import LoadingIndicator from '@/components/TheLoadingIndicator.vue'
  import InstallationsSideviewGeneralTab from '@/components/sideviews/installations/InstallationsSideviewGeneralTab.vue'
  import InstallationsSideviewDevicesTab from '@/components/sideviews/installations/InstallationsSideviewDevicesTab.vue'
  import { TimeUtil } from '@/helpers/TimeUtil'
  import FileSaver from 'file-saver';
  import dayjs from 'dayjs';
  import TheMiniLoader from '@/components/TheMiniLoader.vue'
  import _ from 'lodash';
import { install } from 'vue2-google-maps'

  @Component({
    components: {
      LoadingIndicator,
      InstallationsSideviewGeneralTab,
      InstallationsSideviewDevicesTab,
      TheMiniLoader
    }
  })
  export default class InstallationsSideview extends Vue {
    @Prop()
    loading!: boolean;
  
    @Prop()
    payload!: any;
    
    currTab = this.payload['tab'] | 0;
    jobId = this.payload['jobId'] | 0;
    appointmentId = this.payload['appointmentId'] | 0;
    taskId = this.payload['taskId'] | 0;
  
    isEditing!: boolean;
  
    async hideSideview () {
      commonModule.hideSideview();
    }
  
    get currInstallationId () {
      return this.payload['installationId'];
    }
  
    get currInstallation () {
      return installationsModule.currentInstallation;
    }
  
    get exportPDFEnabled () {
      //TODO Ths is quick and dirty, we need to change this and implement it thourgh feature flags
      return companyModule.company.key == 'GSOP' ? true : false
    }
  
    formatDate (date: Date): string {
      return TimeUtil.formatDateTime(date)
    }
  
    updated() {
      this.isEditing = false;
    }
  
    get isLoading() {
      return installationsModule.isLoadingSideView;
    }
  
    get isPremium () {
      return installationsModule.currentInstallation.info.is_premium;
    }
  
    get tagId () {
      return (installationsModule.currentInstallation.info.tag_id).split("_").pop();
    }

    get currInstallationState() {
        return !(_.isEmpty(this.currInstallation))
    }
  
    refreshData () {
      installationsModule.setIsLoadingSideView(true);
      installationsModule.getInstallationInfo(this.currInstallationId);
      installationsModule.getInstallationDevices(this.currInstallationId);
    }
  
    async editInstallation() {
      await installationsModule.getInstallationInfo(this.currInstallationId);
      commonModule.showModal({ name: 'edit-installation-modal', payload: { view: "installationsSideview"}})
    }
  
    deleteInstallation() {
      commonModule.showModal({ name: 'delete-installation-modal', payload: { installation: this.currInstallation.info}})
    }
  
    close () {
      installationsModule.unsetCurrentInstallation();
  
      switch(this.$route.name) {
        case 'Dashboard':
          break;
        case 'Installations':
          installationsModule.getInstallationsAction(installationsModule.filters);
          break;
        case 'Map':
          installationsModule.getInstallationsAction(installationsModule.filters);
          break;
      }
      this.hideSideview()
    }
  
  };
  