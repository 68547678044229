
import { Component, Vue, Prop } from 'vue-property-decorator'
import LogOutModal from './Modals/LogOutModal.vue'
import AddClientModal from '@/components/Modals/Clients/AddClientModal.vue'
import DeleteClientModal from '@/components/Modals/Clients/DeleteClientModal.vue'
import EditClientModal from '@/components/Modals/Clients/EditClientModal.vue'
import AddInstallationModal from '@/components/Modals/Installations/AddInstallationModal.vue'
import DeleteInstallationModal from '@/components/Modals/Installations/DeleteInstallationModal.vue'
import EditInstallationModal from '@/components/Modals/Installations/EditInstallationModal.vue'
import AddDeviceModal from '@/components/Modals/Devices/AddDeviceModal.vue'
import DeleteDeviceModal from '@/components/Modals/Devices/DeleteDeviceModal.vue'
import EditDeviceModal from '@/components/Modals/Devices/EditDeviceModal.vue'
import ExportDeviceDataModal from '@/components/Modals/Devices/ExportDeviceDataModal.vue'


import { commonModule } from '@/store/modules/store-accessor'

@Component({
  components: {
    LogOutModal,
    DeleteInstallationModal,
    AddClientModal,
    DeleteClientModal,
    EditClientModal,
    AddInstallationModal,
    EditInstallationModal,
    AddDeviceModal,
    DeleteDeviceModal,
    EditDeviceModal,
    ExportDeviceDataModal
  }
})
export default class TheModal extends Vue {
  get modalName () {
    return commonModule.modal.name
  }

  get modalPayload () {
    return commonModule.modal.payload
  }
};
