
import { Component, Vue, Prop } from 'vue-property-decorator'
import { commonModule, clientsModule, installationsModule } from '@/store/modules/store-accessor'
import LoadingIndicator from '@/components/TheLoadingIndicator.vue'
import ClientsSideviewGeneralTab from '@/components/sideviews/clients/ClientsSideviewGeneralTab.vue'
import ClientsSideviewInstallationsTab from '@/components/sideviews/clients/ClientsSideviewInstallationsTab.vue'
import ClientsSideviewDevicesTab from '@/components/sideviews/clients/ClientsSideviewDevicesTab.vue'
import { TimeUtil } from '@/helpers/TimeUtil'
import TheMiniLoader from '@/components/TheMiniLoader.vue'
import _ from 'lodash';

@Component({
  components: {
    LoadingIndicator,
    ClientsSideviewGeneralTab,
    ClientsSideviewDevicesTab,
    ClientsSideviewInstallationsTab,
    TheMiniLoader,
  }
})
export default class ClientsSideview extends Vue {
  @Prop()
  loading!: boolean;

  @Prop()
  payload!: any;

  tab = 0;

  isEditing!: boolean;

  async mounted() {
    installationsModule.getInstallationsByIdAction(this.payload['clientId']);
  }

  async hideSideview () {
    commonModule.hideSideview();
  }

  get currClientId () {
    return this.payload['clientId'];
  }

  get currClient () {
    return clientsModule.currentClient;
  }

  get isLoading() {
    return clientsModule.isLoadingSideView;
  }

  get currClientState() {
    return !(_.isEmpty(this.currClient))
  }

  formatDate (date: Date): string {
    return TimeUtil.formatDateTime(date)
  }

  updated() {
    this.isEditing = false;
  }

  refreshData () {
    clientsModule.setIsLoadingSideView(true);
    clientsModule.getClientsInfo(this.currClientId)
  }
  

  async editClient() {
    await clientsModule.getClientsInfo(this.currClientId)
    commonModule.showModal({ name: 'edit-client-modal', payload: { client: this.currClient.info, contacts: this.currClient.contacts, view: "clientsSideview" }})
  }

  deleteClient() {
    commonModule.showModal({ name: 'delete-client-modal', payload: { client: this.currClient.info }} )
  }

  close () {
    clientsModule.unsetCurrentClient();
    this.hideSideview()
  }

};
