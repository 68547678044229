import { KeycloakInstance } from 'keycloak-js';

export default class KeycloakSession {
  keycloak!: KeycloakInstance
  minValidity = 30

  constructor () {
    this.keycloak = (window as any).sharedKeycloak // shared keycloak from ember or from main.ts in vue
  }

  public updateToken (): Promise<string> {
    const keycloak = this.keycloak
    return new Promise((resolve, reject) => {
      keycloak.updateToken(this.minValidity).then((refreshed: boolean) => {
        if (refreshed) {
          if (!keycloak.token) {
            return reject(new Error('missing key keycloak token'))
          } else {
            return resolve(keycloak.token)
          }
        } else {
          if (keycloak.tokenParsed && keycloak.tokenParsed.exp && keycloak.timeSkew) {
            console.warn('Token not refreshed, valid for ' +
            Math.round(keycloak.tokenParsed.exp || 0 + keycloak.timeSkew || 0 - new Date().getTime() / 1000) + ' seconds')
          }
          if (!keycloak.token) {
            return reject(new Error('missing key keycloak token'))
          } else {
            return resolve(keycloak.token)
          }
        }
      }).catch((error: any) => {
        console.warn('Failed to refresh token', error)
      })
    })
  }

  public userHasAdmin (): boolean {
    return this.hasRole('admin')
  }

  public userHasRole (role:string): boolean {
    return this.keycloak.hasResourceRole(role, 'gas-tools-control-frontend')
  }

  public userHasExternalAPIAccess (): boolean {
    return this.keycloak.hasResourceRole('control_api_access', 'gas-tools-control-externalapi-app')
  }

  private hasRole (role: string): boolean {
    return this.keycloak.hasResourceRole(role, 'gas-tools-control-frontend')
  }
}
