import { BaseApi } from '@/helpers/base-api'

export default class StatsApi extends BaseApi {
  constructor () {
    super('/api/v1/stats')
    this.errorMessage = 'There was an issue when trying to fetch the stats. If this error persists, please contact our support.'
  }

  public getStats = (): Promise<StatsDto> => this.instance.get('')
  public getStatsLastMonths = (): Promise<StatsLastMonthsDto> => this.instance.get('/last/months')
  public getStatsActions = (): Promise<StatsActionsDto> => this.instance.get('/actions/type')

}