
import { Component, Vue, Prop } from 'vue-property-decorator'
import { commonModule, installationsModule, devicesModule, companyModule } from '@/store/modules/store-accessor'
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete';
import dayjs from 'dayjs';

@Component({
  components: {}
})
export default class ExportDeviceDataModal extends Vue {
  @Prop() payload!: any;

  loadingMetrics = true;
  dateRangeMenuState = false;

  dateRange = [dayjs().startOf('day').format('YYYY-MM-DD'), dayjs().endOf('day').format('YYYY-MM-DD')];
  deviceMetrics: Record<string, unknown> = {};

  get currInstallation () {
    return installationsModule.currentInstallation;
  }

  get currDeviceId () {
    return devicesModule.currentDevice.data.deviceId;
  }

  get allInstallations() {
    return commonModule.installationsCombo;
  }

  get isLoadingMetrics () {
    return this.loadingMetrics;
  }
  get installationDescriptionIsEnabled() {
    return (companyModule.company?.preferences as any)?.installation_description;
  }

  get deviceMetricList() {
    const translated : string[]= this.deviceMetrics.historical as string[];//(this.deviceMetrics.historical as string[]).map((item) => this.$i18n.t(item))
    return (translated?.map((item) => { return {value: item,  text: this.$i18n.t(item)} }))
  }

  dateRangeUpdated(date: any) {
    let sorted = date.sort((a: any, b: any) => {
      var dateA = dayjs(a).unix(),
          dateB = dayjs(b).unix();
      if (dateB > dateA) {
        return -1;
      } else {
        return 1;
      }
    })
    let dateFrom = dayjs(sorted[0]).startOf('day').toISOString();
    let dateTo = dayjs(sorted[1]).endOf('day').toISOString();
    
    this.exportModel.time_from = dateFrom;
    this.exportModel.time_to = dateTo;
  }

  get dateRangeText () {
    // let dateRangeText = this.dateRange.map((item) => dayjs(item).format('YYYY-MM-DD HH:mm'))
    let sorted: any = this.dateRange.sort((a: any, b: any) => {
      var dateA = dayjs(a).unix(),
          dateB = dayjs(b).unix();
      if (dateB > dateA) {
        return -1;
      } else {
        return 1;
      }
    })


    return sorted.join(' - ')
  }
 
  exportModel = {
    time_from: dayjs().startOf('day').toISOString(),
    time_to: dayjs().endOf('day').toISOString(),
    interval: this.intervalCombo[0].period,
    metric: [],
    file_type: this.exportType[0].file_type
  }

  google_address:any;

  evt:any;

  dateRangeRule = [
    (val: any) => {
      if ((val.split(' - '))[1] !== undefined) {
        const date1 = dayjs((val.split(' - '))[1])

        let dateDiff = date1.diff((val.split(' - '))[0], 'day', true) //
        let dateLimit = this.exportModel.interval.includes('d') ? 365 : 31 

        return (dateDiff <= dateLimit) || this.$i18n.t("date_range_error", {'days': dateLimit})
      }
      return true;
    },
    (val: any) => ((val.split(' - '))[1] !== undefined) || this.$i18n.t("date_range_required"),
  ]

  metricsRule = [
    (val: any) => val?.length > 0 || this.$i18n.t("metrics_required"),
  ]

  intervalRule = [
    (val: any) => !!val || this.$i18n.t("interval_required"),
  ]

  exportFileTypeRule = [
    (val: any) => !!val || this.$i18n.t("export_type_required"),
  ]



  //Add Validation for two 



  installationText(item: any) {
    let installation_arr = item.is_business 
    ?
    [item.tag_id.split('_').pop(), `${item.address ? item.address : item.area }`, (item.description && this.installationDescriptionIsEnabled) ? item.description : '', `${item.entity_name} (${item.client_name})`, item.phone]
    :
    [item.tag_id.split('_').pop(), `${item.address ? item.address : item.area }`,(item.description && this.installationDescriptionIsEnabled) ? item.description : '', item.client_name, item.phone]

    return installation_arr.filter(Boolean).join(' | ')
  }

  async hideModal () {
    commonModule.hideModal();
  }

  async doDeviceExport () {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      await devicesModule.exportDeviceData({ deviceId: this.currDeviceId, exportModel: this.exportModel});
      this.hideModal();  
    }
  }

  get intervalCombo() {
      const intervalCombo: any = [
        { "period" : '1m' , "text" : `1 ${this.$i18n.t("minute")}` },
        { "period" : '30m' , "text" : `30 ${this.$i18n.t("minutes")}` },
        { "period" : '1h' , "text" : `1 ${this.$i18n.t("hour")}`  },
        { "period" : '1d' , "text" : `1 ${this.$i18n.t("day")}` },
        { "period" : '7d' , "text" : `7 ${this.$i18n.t("days")}` },
        { "period" : '30d' , "text" : `30 ${this.$i18n.t("days")}` },
      ];
      return intervalCombo
  }

  get exportType() {
      const fileTypeCombo: any = [
        // { "file_type" : 'CSV' },
        { "file_type" : 'JSON' },
      ];
      return fileTypeCombo
  }

  async mounted() {
    commonModule.initSnackbar({});
    this.loadingMetrics = true;
    this.deviceMetrics = await devicesModule.getDeviceMetrics(this.currDeviceId);
    this.loadingMetrics = false;  
  }

  isNumber(evt:any) {
      evt = evt || window.event;
      let charCode = evt.which || evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
      return false;
  }
};
