
import { Component, Vue, Prop } from 'vue-property-decorator'
import { TimeUtil } from '@/helpers/TimeUtil'
import DashboardCardBarChart from "@/components/Cards/DashboardCardBarChart.vue";
import DashboardCardPieChart from "@/components/Cards/DashboardCardPieChart.vue";
import DeviceOverviewCard from "@/components/Cards/DeviceOverviewCard.vue";
import DeviceOverviewSwitchCard from "@/components/Cards/DeviceOverviewSwitchCard.vue";
import DeviceOverviewButtonCard from "@/components/Cards/DeviceOverviewButtonCard.vue";
import DeviceOverviewHeatingBalanceSliderCard from "@/components/Cards/DeviceOverviewHeatingBalanceSliderCard.vue";
import DashboardCardFaultsPieChart from "@/components/Cards/DashboardCardFaultsPieChart.vue";
import DeviceOverviewRoomTargetCard from "@/components/Cards/DeviceOverviewRoomTargetCard.vue";
import dayjs from 'dayjs';

@Component({
  components: {
    DashboardCardBarChart,
    DashboardCardPieChart,
    DeviceOverviewCard,
    DeviceOverviewSwitchCard,
    DeviceOverviewButtonCard,
    DeviceOverviewHeatingBalanceSliderCard,
    DeviceOverviewRoomTargetCard,
    DashboardCardFaultsPieChart,
  }
})

export default class HeatingControllerGeneralView extends Vue {
 @Prop() device: Record<string, any>;

  formatDate (date: Date): string {
    return TimeUtil.formatDateTime(date)
  }

  get rfCommunicationLost() {
    if( (dayjs(this.device.data.properties?.ot?.rf?.time) < dayjs().subtract(2,'hours'))  && this.device.data.onlineState) {
        return true;
    }
    return false;
  }

  get boilerFault() {
    return this.device.data.properties.ot.boiler.fault > 0;
  }

};
