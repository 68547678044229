
import { Component, Vue, Watch } from 'vue-property-decorator'
import { commonModule, installationsModule, distributorModule, devicesModule, filterModule } from '@/store/modules/store-accessor'
import Dropdown from '../components/dropdown/Dropdown.vue'
import InventoryFilter from '@/components/Filters/InventoryFilter.vue'
import DropdownItem from '../components/dropdown/DropdownItem.vue'
import FilterDropdown from '../components/dropdown/filter/FilterDropdown.vue'
import { VBreadcrumbsDivider } from 'vuetify/lib/components'
import {getIconFromDeviceType} from '@/helpers/data'

@Component({
  components: { Dropdown, DropdownItem, FilterDropdown, InventoryFilter }
})
export default class Inventory extends Vue {
  tableheaders: any [] = [
    { text: this.$t("type"), align: 'left', sortable: true, class: 'row-style', value: 'type' } ,
    {
      text: this.$t("serial_number"),
      align: 'left',
      class: 'row-style',
      sortable: true,
      value: 'serial_no'
    },
    {
      text: this.$t("device_id"),
      align: 'left',
      class: 'row-style',
      sortable: true,
      value: 'device_id'
    },
    { text: this.$t("installer_company_name"), sortable: true, class: 'row-style', value: 'entity_name' },
    { text: this.$t("installer_contact"), sortable: true, class: 'row-style', value: 'contact' },
    { text: this.$t("items"), sortable: true, class: 'row-style', value: 'installation_item' },
    { text: this.$t("installed_at"), sortable: true, class: 'row-style', value: 'readable_installation_date' },
    { text: this.$t("client_name"), sortable: true, class: 'row-style', value: 'client_name' },
    { text: this.$t("Online"), sortable: true, class: 'row-style', value: 'onlineState' },
    { text: this.$t("boiler_status"), sortable: true, class: 'row-style', value: 'fault' },
    { text: '', align: 'right', sortable: false, class: 'row-style', value: 'id' } 
  ]

  model: any = {
    search: '',
    selected: [] as string[]
  }

  get devicesLength() {
    return distributorModule.inventoryCount
  }

  get searchLabel() {
    return this.$t("devicesSearch")
  }

  get devices(){
    return distributorModule.inventory
  }

  get currInstallation(){
    return installationsModule.currentInstallation
  }

  get isLoadingTable() {
    return distributorModule.isLoadingTable;
  }

  getIconFromDeviceType(deviceType: string) {
    return getIconFromDeviceType(deviceType);
  }

  async refreshData() {
    distributorModule.getInventoryCount();
    distributorModule.getDevices(distributorModule.filters);
    filterModule.getInventoryFilters();
  }

  async mounted () {
    /*
     TODO: Handle installations properly when UX is clear.
     */
    distributorModule.getInventoryCount();
    distributorModule.getDevices(distributorModule.filters);
    filterModule.getInventoryFilters();
    commonModule.initSnackbar({});
  }

  async selectDevice (device: any) {
    if(device.installation_id) {
      distributorModule.setIsLoadingTable(true);
      await devicesModule.getDeviceInfo(device.device_id);
      distributorModule.setIsLoadingTable(false);
      commonModule.showSideview({ name: 'distributor-device-sideview' , payload: { deviceId: device.device_id } })
    }
  }
}
