
  import { commonModule, installationsModule, devicesModule, distributorModule } from '@/store/modules/store-accessor'
  import Vue from "vue";
  import { Component, Prop } from "vue-property-decorator";
  import { calcDuration, appointmentStatusToColor, appointmentStatusToName } from "../../helpers/data";
  import KeycloakSession from '@/helpers/keycloak-session'

  @Component
  export default class DashboardActiveFaultsTable extends Vue {
    @Prop() readonly isLoading: boolean;
    @Prop() items: [];
    
    get kcSession() {
      return new KeycloakSession()
    }

    get isDistributor() {
      return this.kcSession.userHasRole('distributor');
    }

    get isTechnicianCompany () {
      return this.kcSession.userHasRole('technician_company');
    }

    get isLoadingTable() {
      return devicesModule.isLoadingTable;
    }


    activeFaults_headers = [
      {
        text: this.$i18n.t("date"),
        align: "start",
        value: "readable_date",
      },
      { text: this.$i18n.t("device_id"), value: "deviceId" },
      { text: this.$i18n.t("fault"), value: "fault" },
    ];

    async selectDevice (device: any) {
      devicesModule.setIsLoadingTable(true);

      if(this.isTechnicianCompany) {
        devicesModule.setIsLoadingTable(true);
        await devicesModule.getDeviceInfo(device.deviceId);
        await installationsModule.getInstallationInfo(device.installation_id);
        devicesModule.setIsLoadingTable(false);
        commonModule.showSideview({ name: 'devices-sideview' , payload: { installationId: device.installation_id, deviceId: device.deviceId} })
      }
      else if(this.isDistributor) {
        await devicesModule.getDeviceInfo(device.deviceId);
        commonModule.showSideview({ name: 'distributor-device-sideview' , payload: { deviceId: device.deviceId } })
      }
      devicesModule.setIsLoadingTable(false);
    }
    

  }
  