
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { calcDuration, appointmentStatusToColor, appointmentStatusToName } from "../../helpers/data";

@Component
export default class DashboardTodaysAppointmentsTable extends Vue {
  @Prop() readonly headers: any;
  @Prop() readonly items: any;
  @Prop() readonly search: string;
  @Prop() readonly isLoading: boolean;

  setStatusColor(status: string) {
    return appointmentStatusToColor(status);
  }

  setStatusText(status: string) {
    return appointmentStatusToName(status);
  }

  setDuration(start: Date, end: Date) {
    return calcDuration(start, end);
  }
}
