import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";

import { ActionContext } from "vuex";
import FilterApi from '@/api/filter-api'


@Module({ namespaced: true, name: 'filters' })
export default class FiltersModule extends VuexModule {

  public devicesFilters: DevicesFilterDto = {} as DevicesFilterDto;
  public inventoryFilters: DevicesFilterDto = {} as DevicesFilterDto;
  public isLoading = true;

  @Action({ commit: 'setDevicesFilters'})
  async getDevicesFilters (): Promise<DevicesFilterDto[]> {
    const filterApi = new FilterApi()
    const devicesFilters = await filterApi.getDevicesFilters();
    this.setIsLoading(false);
    return devicesFilters
  }

  @Action({ commit: 'setInventoryFilters'})
  async getInventoryFilters (): Promise<DevicesFilterDto[]> {
    const filterApi = new FilterApi()
    const devicesFilters = await filterApi.getInventoryFilters();
    this.setIsLoading(false);
    return devicesFilters
  }

  @Mutation
  public setDevicesFilters (devicesFilters: DevicesFilterDto): void {
    this.devicesFilters = devicesFilters
  }

  @Mutation
  public setInventoryFilters (inventoryFilters: DevicesFilterDto): void {
    this.inventoryFilters = inventoryFilters
  }

  @Mutation
  public setIsLoading(isLoading: boolean): void {
    this.isLoading = isLoading
  }

}