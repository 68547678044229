import { BaseApi } from '@/helpers/base-api'

export default class DistributorApi extends BaseApi {
  constructor () {
    super('/api/v1/distributor')
    this.errorMessage = 'There was an issue when trying to fetch the distributor data. If this error persists, please contact our support.'
  }

  public getInventory = (query: string): Promise<DeviceDto[]> => this.instance.get(`/inventory${query}`)
  public getInventoryCount = (): Promise<CountDto> => this.instance.get(`/inventory/count`)
  public getDistributorCompanies = (): Promise<[]> => this.instance.get(`/companies`)
  
}
