
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import ApexChart from "vue-apexcharts";
import { devicesModule } from '@/store/modules/store-accessor'

import i18n from "@/i18n";

@Component({
  components: { ApexChart },
})
export default class DashboardCardPieChart extends Vue {
  @Prop() readonly title: string;
  @Prop() readonly isLoading: boolean;

  apexLoading = false;

  mounted() {
    setTimeout(() => {
      this.apexLoading = true;
    });
  }

  get countFaults() {
    return devicesModule.deviceActiveFaultsChart[0] +  devicesModule.deviceActiveFaultsChart[1]
  }

  get deviceActiveFaultsDistribution() {
    return devicesModule.deviceActiveFaultsChart;
  }
  
  options = {
        chart: {
          width: 380,
          type: 'donut',
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270
          }
        },
        dataLabels: {
          enabled: false
        },
        fill: {
          type: 'gradient',
        },
        legend: {
          formatter: function(val: any, opts: any) {
            if (opts.seriesIndex == 0 ) {
              return i18n.t("non_blocking");
            }
            else {
              return i18n.t("blocking");
            }
          }
        },
        labels: [i18n.t("non_blocking"), i18n.t("blocking")],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
  }
}
