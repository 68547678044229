
import Vue from "vue";
import ApexChart from "vue-apexcharts";
import { Component, Prop, Watch } from "vue-property-decorator";
import { TimeUtil } from '@/helpers/TimeUtil'
import i18n from "@/i18n";
import { devicesModule } from '@/store/modules/store-accessor'
import dayjs from "dayjs";

@Component({
  components: { ApexChart },
})
export default class DeviceSideviewBarChart extends Vue {
  @Prop() readonly cols: number;
  @Prop() readonly chartName: string;
  @Prop() readonly deviceId: string;
  @Prop() dataMetrics: Array<any>;
  @Prop() readonly dateRange: Record<string, any>;
  @Prop({default: 'historical'}) typeOfData: string;
  @Prop({default: false}) stacked: boolean;
  apexLoading = false;

  chartOptions = {
    chart: {
      height: 380,
      width: "100%",
      type: "area",
      stacked: false,
      animations: {
        initialAnimation: {
          enabled: false
        }
      },
      events: {
        beforeZoom: (chartContext: any, { xaxis }: any) => {
          let time_from = parseInt(xaxis.min) * 1000000
          let time_to = parseInt(xaxis.max) * 1000000

          let updatedDateRange = { date_from: time_from, date_to: time_to };
          this.$emit('update-date-range', updatedDateRange);

          if(this.typeOfData === 'metadata') {
            devicesModule.getDeviceMetadata({ deviceId: this.deviceId, time_from: time_from, time_to: time_to, metric: this.dataMetrics })
          }
          else {
            devicesModule.getDeviceData({ deviceId: this.deviceId, time_from: time_from, time_to: time_to, metric: this.dataMetrics })
          }
        }
      },
      toolbar: {
        tools: {
          pan: false,
          reset: false
        },
        export: {
          csv: {
            filename: "File Name",
            columnDelimiter: ',',
            headerCategory: 'timestamp',
            headerValue: 'value',
            categoryFormatter(x: any) {
              return x
            },
            valueFormatter(y: any) {
              return y
            }
          },
          svg: {
            filename: '',
          },
          png: {
            filename: '',
          }
        }
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          total: { enabled: true },
        },
      },
    },
    dataLabels: {
      enabled: false,
      formatter: function (val: any, opts: any) {
        if(val) {
          return val.toFixed(1)
        }
      },
    },
    stroke: {
      curve: 'smooth',
      width: 3,
    },
    legend: {
      formatter: function(seriesName: any, opts: any) :any {

        return [i18n.t(seriesName)]
      }
    },
    yaxis: {
      tickAmount:4,
      decimalsInFloat: 3
    },
    xaxis: {
      type: 'datetime',
      labels: {
        datetimeUTC: false
      }
    },
    tooltip: {
      y: {
        formatter: (val: any) => {
         if(val) {
           return val.toFixed(3)
         }
        },
        title: {
          formatter: (seriesName: string) => this.$i18n.t(seriesName),
        },
      },
      x: {
        formatter: (val: any) => {
          if(val) {
           return TimeUtil.formatInfluxDateTime(val) 
          }
        }
      }
    }
  };

  async mounted() {
    //Fetch initial values for date range 1

    const time_to = dayjs().valueOf() * 1000000//ValueOf gives time in Milliseconds time in browser local time
    const time_from = dayjs().subtract(this.chartApexAreaSelect.days, 'days').valueOf() * 1000000//ValueOf gives time in Milliseconds time in browser local time
    if(this.typeOfData === 'metadata') {
      devicesModule.getDeviceMetadata({ deviceId: this.deviceId, time_from: time_from, time_to: time_to, metric: this.dataMetrics })
    }
    else {
      devicesModule.getDeviceData({ deviceId: this.deviceId, time_from: time_from, time_to: time_to, metric: this.dataMetrics })
    }

    this.chartOptions.chart.stacked = this.stacked;
    setTimeout(() => {
      this.apexLoading = true;
    });

    let exportFileName = `${this.deviceId}-${(this.$i18n.t(this.chartName)).toString().replace(/ /g,"_")}_export` ;
    this.chartOptions.chart.toolbar.export.csv.filename = exportFileName;
    this.chartOptions.chart.toolbar.export.svg.filename = exportFileName;
    this.chartOptions.chart.toolbar.export.csv.filename = exportFileName;
  }

  selectTimeRange = [
    { days: 1, text: this.$i18n.t("day") },
    { days: 7, text: this.$i18n.t("week") },
    { days: 30, text: this.$i18n.t("month") }
  ]

  chartApexAreaSelect = this.selectTimeRange[0];

  @Watch('dateRange')
  async changeDataTimeRange() {
    const time_to = this.dateRange.date_to //ValueOf gives time in Milliseconds time in browser local time
    const time_from = this.dateRange.date_from//ValueOf gives time in Milliseconds time in browser local time

    if(this.typeOfData === 'metadata') {
      devicesModule.getDeviceMetadata({ deviceId: this.deviceId, time_from: time_from, time_to: time_to, metric: this.dataMetrics })
    }
    else {
      devicesModule.getDeviceData({ deviceId: this.deviceId, time_from: time_from, time_to: time_to, metric: this.dataMetrics })
    }
  }

  get dataSeries() {
    let series: Array<any> = [];
    this.dataMetrics.forEach(element => {
      series.push(devicesModule.currDeviceData[element])
    });

    return series.filter(Boolean)
  }

}
