import moment from "moment";
import i18n from "@/i18n";

function formatDate(dateTime: string) {
  if (dateTime !== null && dateTime !== undefined) {
    return moment(dateTime).format("MMM DD, YYYY HH:mm:ss");
  } else {
    return "-";
  }
}

function formatDateCalendar(dateTime: Date) {
  if (dateTime !== null && dateTime !== undefined) {
    return moment(dateTime).format("YYYY-MM-DD HH:mm");
  } else {
    return "-";
  }
}

function formatOnlyDate(dateTime: Date) {
  if (dateTime !== null && dateTime !== undefined) {
    return moment(dateTime).format("MMM DD, YYYY");
  } else {
    return "";
  }
}

function convertMinsToHrsMins(mins: number) {
  const hours = mins / 60;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);
  if (rhours == 0) {
    if (rminutes < 10) {
      return rminutes + "m";
    }
    else {
      return ("0" + rminutes).slice(-2) + "m";
    }
  } else if (rminutes == 0) {
    return rhours + "h";
  }

  else {
    return rhours + "h " + ("0" + rminutes).slice(-2) + "m";
  }
}

function calcDuration(start: Date, end: Date) {
  if (
    start !== null ||
    start !== undefined ||
    end !== null ||
    end !== undefined
  ) {
    const newStart = moment(start);
    const newEnd = moment(end);

    const duration = moment.duration(newEnd.diff(newStart));

    const durationHoursMinutes = convertMinsToHrsMins(duration.asMinutes());

    return durationHoursMinutes;
  } else {
    return "-";
  }
}

function appointmentStatusToName(status: string) {
  if (status == '1') return 'appointmentCanceled';
  else if (status == '2') return 'appointmentPending';
  else if (status == '3') return 'appointmentOngoing';
  else if (status == '4') return 'appointmentComplete';
  else if (status == '5') return 'appointmentInComplete';
  else return "appointmentPending";
}

function appointmentStatusToColor(status: string) {
  if (status == '1') return 'red';
  else if (status == '2') return 'orange';
  else if (status == '3') return 'blue';
  else if (status == '4') return '#42a142';
  else if (status == '5') return '#db1a1a';
  else return 'orange';
}

function addOpacity(color: any, opacity: number): string {
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
}

function roundMinutes(minutes: number) {
  minutes = Math.ceil(minutes / 15) * 15 - 15;
  if(minutes >= 59 || minutes === 0) return '00'
  else if(minutes < 10) return `0${minutes}`
  else return minutes.toString();
}

function sortDateRange(dateRange: any) {
  const startDate = dateRange[0];
  const endDate = dateRange[1];

  const startDateConv = new Date(startDate);
  const endDateConv = new Date(endDate);

  if(startDateConv <= endDateConv) {
   dateRange[0] = startDate;
   dateRange[1] = endDate;
  } else {
    dateRange[0] = endDate;
    dateRange[1] = startDate;
  }
}

function formatDatesRangeText(startDate: any, endDate: any) {
  if (!startDate && !endDate) return null

  const [startYear, startMonthmonth, startDay] = startDate.split('-')
  const [endYear, endMonthmonth, endDay] = endDate.split('-')

  const startDateConv = new Date(startDate);
  const endDateConv = new Date(endDate);

  if(startDateConv <= endDateConv) {
    return `${startDay}/${startMonthmonth}/${startYear} -- ${endDay}/${endMonthmonth}/${endYear}`
  } else {
    `${endDay}/${endMonthmonth}/${endYear} -- ${startDay}/${startMonthmonth}/${startYear}`
  }
}

function primaryContactExists(model: any) {
  let exists = false;
  model.contacts.forEach((contact: any) => 
    {
      if (contact.is_primary == true) {
        exists = true;
      }
    });
  return exists;
}


function statusCodeMsg(statusCode: string) {
  const STATUS_CODES: any = {
    "400": "400_BAD_REQUEST_ERROR_API",
    "401": "401_UNAUTHORIZED_ERROR_API",
    "403": "403_FORBIDDEN_ERROR_API",
    "404": "404_NOT_FOUND_ERROR_API",
    "406": "406_NOT_ACCEPTED_ERROR_API",
    "409": "409_CONFLICT_ERROR_API",

    "500": "500_INTERNAL_SERVER_ERROR_API",
    "502": "502_BAD_GATEWAY_ERROR_API",
    "503": "503_SERVICE_UNAVAILABLE_API"
  };

  if(STATUS_CODES[statusCode]) {
    return `${i18n.t(STATUS_CODES[statusCode])} ${statusCode}`;
  }

  return "API_GENERAL_ERROR";
}

function getIconFromDeviceType(deviceType: string) {
  switch (deviceType) {
    case 'DOMX_HC_OT':
      return 'mdi-water-boiler'
    case 'DOMX_HC_MB':
      return 'mdi-heat-pump-outline'
    case 'DOMX_IAQ':
      return 'mdi-air-filter'
    case 'SHELLY_EM_3PH':
      return 'mdi-meter-electric-outline'
    case 'SHELLY_EM_1PH':
      return 'mdi-meter-electric-outline'
    case 'SHELLY_EM_1PH_W_RELAY':
      return 'mdi-meter-electric-outline'
    case 'SHELLY_EM_3PH_W_RELAY':
      return 'mdi-meter-electric-outline'      
    default:
      return '-'      
  }
}

function getWiFiSignalLevelFromRSSI(rssi: number) {
  if (rssi > -30)
    return  {color: "green" , message: "excellent"}
  else if (rssi > -67 && rssi <= -30)
    return {color: "green" , message: "very_good"}
  else if (rssi > -70 && rssi <= -67)
    return {color: "green" , message: "good"} 
  else if (rssi > -80 && rssi <= -70)
    return {color: "orange" , message: "weak"}
  else if (rssi > -90 && rssi <= -80)
    return {color: "red" , message: "very_weak"}
  else
    return {color: "red" , message: "poor"}
}

function MBDeviceOperationMode(op: number) {
  switch (op) {
    case 0:
      return {color: "gray" , icon: "mdi-hvac-off", text: "OFF"}
    case 1:
      return {color: "green" , icon: "mdi-refresh-auto", text: "AUTO"}
    case 2:
      return {color: "blue" , icon: "mdi-snowflake", text: "COOLING"}
    case 3:
      return {color: "warning" , icon: "mdi-fire", text: "HEATING"}
    default: 
      return {color: "gray" , icon: "mdi-hvac-off", text: "OFF"}

  }
}

function convertEnglishToGreek(str: string): string {
  type latinToGreek = {
    [key: string]: string
  }
  
  const latinToGreek: latinToGreek = {
    'a': 'α', 'b': 'β', 'c': 'γ', 'd': 'δ', 'e': 'ε', 'f': 'φ', 'g': 'γ', 'h': 'η', 'i': 'ι', 'j': 'ξ', 'k': 'κ', 'l': 'λ', 'm': 'μ',
    'n': 'ν',  'o': 'ο', 'p': 'π', 'q': 'θ', 'r': 'ρ', 's': 'σ', 't': 'τ', 'u': 'υ', 'v': 'β', 'w': 'ω', 'x': 'χ', 'y': 'ψ', 'z': 'ζ'
  };
  
  return str.replaceAll('ς', 'σ').replace(/[a-z]/gi, function(match) {
    return latinToGreek[match.toLowerCase()] || match;
  });
}

function sortTableDates(a: string, b: string) {
  const dateA: any = new Date(a);
  const dateB: any = new Date(b);
  return dateA - dateB;
}

async function setGoogleMapsInfoToModel(modelName: Record<string, any>, latitude: any, longitude: any) {
  let lang = localStorage.getItem('lang') || 'el';
  if(lang === 'gr') {
    lang = 'el';
  }

  try {
    const googleKey = (process.env.NODE_ENV === "production") ? "GOOGLE_MAPS_KEY" : "AIzaSyDbgvgejSNtTI410Z1ihbNGl-0iEpfPl0k";
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&language=${lang}&key=${googleKey}`;

    const response = await fetch(apiUrl);
    const responseData = await response.json();

    if (responseData && responseData.results && responseData.results.length > 0) {
      const result = responseData.results[0];

      const { lat, lng } = result.geometry.location;
      const address = result.formatted_address;
      let area = '';
      for (const component of result.address_components) {
        if (component.types.includes('locality') || component.types.includes('political')) {
          area = component.long_name;
          break;
        }
      }

      let zipCode = '';
      for (const component of result.address_components) {
        if (component.types.includes('postal_code')) {
          zipCode = component.long_name.replace(/ /g,'');
          break;
        }
      }

      modelName.address = address;
      modelName.area = area;
      modelName.latitude = lat;
      modelName.longitude = lng;
      modelName.zip_code = zipCode;

    }
  } catch (error) {
    console.error('Error fetching data from Google Maps API');
    return 0;
  }
}

function convertJSONToCSV(jsonData: any) {
  const array = typeof jsonData !== 'object' ? JSON.parse(jsonData) : jsonData;

  // Extract the headers (keys)
  const headers = Object.keys(array[0]);

  // Map data to CSV format
  const csvRows = array.map((row: any) => {
      return headers.map(header => {
          const escaped = ('' + row[header]).replace(/"/g, '\\"');
          return `"${escaped}"`;
      }).join(',');
  });

  // Combine headers and rows
  const csvData = [headers.join(','), ...csvRows].join('\n');

  return csvData;
}

export {
  formatDate,
  formatDateCalendar,
  formatOnlyDate,
  calcDuration,
  appointmentStatusToName,
  appointmentStatusToColor,
  addOpacity,
  roundMinutes,
  formatDatesRangeText,
  sortDateRange,
  statusCodeMsg,
  primaryContactExists,
  getIconFromDeviceType,
  getWiFiSignalLevelFromRSSI,
  MBDeviceOperationMode,
  convertEnglishToGreek,
  sortTableDates,
  setGoogleMapsInfoToModel,
  convertJSONToCSV
};
