
  import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
  import { commonModule, installationsModule, devicesModule } from '@/store/modules/store-accessor'
  import { TimeUtil } from '@/helpers/TimeUtil'
  import Dropdown from '../../../components/dropdown/Dropdown.vue'
  import DropdownItem from '../../../components/dropdown/DropdownItem.vue'
  import { getIconFromDeviceType, sortTableDates } from "@/helpers/data";

  @Component({
    components: { Dropdown, DropdownItem }
  })
  export default class InstallationsSideviewDevicesTab extends Vue {
    @Prop() installation: Record<string, any>;

    tableheaders: any [] = [
      { text: this.$t("type"), align: 'left', sortable: false, class: 'row-style', value: 'type', width: '10px' },
      { text: this.$t("brand"), align: 'left', class: 'row-style', sortable: true, value: 'make_model'},
      { text: this.$t("serial_number"), align: 'left', class: 'row-style', sortable: true, value: 'serial_no', width: '170px' },
      { text: this.$t("device_id"), align: 'left', class: 'row-style', sortable: true, value: 'device_id'},
      { text: this.$t("device_friendly_name"), align: 'left', class: 'row-style', sortable: true, value: 'device_name'},
    ]
  
    expandedItems: any[] = [];
  
    mounted() {
      installationsModule.getInstallationDevices(this.installation.info.id)
    }

    formatDate (date: Date): string {
      return TimeUtil.formatDateTime(date)
    }

    getIconFromDeviceType(deviceType: string) {
      return getIconFromDeviceType(deviceType);
    }
  
    get devices () {
      return installationsModule.currentInstallationDevices
    }

    async selectDevice (device: any) {
      await devicesModule.getDeviceInfo(device.device_id);
      commonModule.showSideview({ name: 'devices-sideview' , payload: { deviceId: device.device_id, installationId: this.installation.info.id } })
    }

  };
  