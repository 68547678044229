
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { commonModule, installationsModule, companyModule, devicesModule, distributorModule } from '@/store/modules/store-accessor'
import LoadingIndicator from '@/components/TheLoadingIndicator.vue'
import DevicesSideviewGeneralTab from '@/components/sideviews/devices/DevicesSideviewGeneralTab.vue'
import DevicesSideviewInfoTab from '@/components/sideviews/devices/DevicesSideviewInfoTab.vue'
import DevicesSideviewChartsTab from '@/components/sideviews/devices/DevicesSideviewChartsTab.vue'
import DevicesSideviewFaultsTab from '@/components/sideviews/devices/DevicesSideviewFaultsTab.vue'
import { TimeUtil } from '@/helpers/TimeUtil'
import FileSaver from 'file-saver';
import moment from 'moment';
import TheMiniLoader from '@/components/TheMiniLoader.vue'
import { getWiFiSignalLevelFromRSSI } from '@/helpers/data'

@Component({
  components: {
    LoadingIndicator,
    DevicesSideviewGeneralTab,
    DevicesSideviewInfoTab,
    DevicesSideviewChartsTab,
    DevicesSideviewFaultsTab,
    TheMiniLoader
  }
})
export default class DistributorDeviceSideview extends Vue {
  @Prop()
  loading!: boolean;

  @Prop()
  payload!: any;
  
  currTab = this.payload['tab'] | 0;

  isEditing!: boolean;

  timer!: any;

  @Watch('currTab')
  checkTimer() 
  {
    if (this.currTab == 0) {
        this.timer = setInterval(() => {
          if (this.currDevice.info != {} as any) {
            this.refreshData()
          }
        } , 10000);
    }
    else {
      clearInterval(this.timer);
    }
  }

  async hideSideview () {
    commonModule.hideSideview();
  }

  get currDevice () {
    return devicesModule.currentDevice
  }

  formatDate (date: Date): string {
    return TimeUtil.formatDateTime(date)
  }

  updated() {
    this.isEditing = false;
  }

  wifiSignalToMessage(rssi: number) {
    return getWiFiSignalLevelFromRSSI(rssi)["message"]
  }

  wifiSignalToColor(rssi: number) {
    return getWiFiSignalLevelFromRSSI(rssi)["color"]
  }

  async mounted() {
    this.timer = setInterval(() => {
      if (this.currDevice.info != {} as any) {
        this.refreshData()
      }
    } , 10000);
  }


  get isLoading() {
    return false;
  }

  refreshData () {
    devicesModule.getDeviceInfo(this.currDevice.info.device_id);
    
  }

  close () {
    clearInterval(this.timer);
    devicesModule.unsetCurrentDevice()
    this.hideSideview()
  }

};
