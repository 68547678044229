
import { filterModule, distributorModule } from "@/store/modules/store-accessor";
import Vue from "vue";
import { Component } from 'vue-property-decorator'
import FilterList from '@/components/Filters/FilterList.vue'
import { getIconFromDeviceType } from "@/helpers/data";

@Component({
  components: {
    FilterList,
  },
})
export default class DevicesFilter extends Vue {
  get devicesFilters() {
    return filterModule.inventoryFilters.deviceTypes?.map((item) => {
      return {
        icon: getIconFromDeviceType(item),
        text: item
      }
    })
  }

  owesOptions = [{text: [this.$t('false')], value: ['false']}, {text: [this.$t('true')], value: ['true']}];

  selectedDeviceTypes: string[];

  updateDeviceTypes(data: any[]) {
    this.selectedDeviceTypes = data?.map(i => i.text);
    this.submit();
  }

  async submit() {
    distributorModule.setIsLoadingTable(true);
    let queryObj: any = {};

    if (this.selectedDeviceTypes != undefined && this.selectedDeviceTypes.length > 0) {
      queryObj["deviceTypes[]"] = this.selectedDeviceTypes;
    }

    this.$router.replace({ query: queryObj }).catch(err => {/* */ });

    // Check if user selected any of the queries
    const queryIndex = this.$route.fullPath.indexOf('?');
    if(queryIndex >= 0) {
      const query = this.$route.fullPath.substring(queryIndex);
      distributorModule.setQueryObject(queryObj);
      distributorModule.setFilters(query);
    } else {
      distributorModule.setFilters("");
      distributorModule.setQueryObject(queryObj); // Will be empty ({})
    }

    await distributorModule.getDevices(distributorModule.filters)
    distributorModule.setIsLoadingTable(false);
  }
}
