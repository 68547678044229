<template>
  <v-col :cols="cols">
    <v-card class="mx-1 mt-4 mb-1">
      <v-card-title class="pa-6 pb-3">
      </v-card-title>
      <v-card-text class="pa-6 pt-0">
        <v-row no-gutters>
          <v-col cols="6" class="my-auto">
            <div
              class=""
              :style="{ 'font-size': text_font_size, 'font-weight': 'bold' }"
              >{{ title_number }}
            </div>
            <div
              class="subtitle-1"
              v-text="$t('indoor') + indoor.toFixed(1) + ' °C'"
            ></div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col >
            <round-slider
              class="slider"
              v-model="currentValue"
              start-angle="315"
              end-angle="+270"
              line-cap="round"
              radius="100"
              :min="min"
              :max="max"
              :step="step"
              editableTooltip="false"
              pathColor="#ff970a"
              rangeColor="#284BAE"
              handleSize="+10"
              :change="sliderValueChange"
              :tooltipFormat="formatValue"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import RoundSlider from "vue-round-slider";

@Component({
  components: { RoundSlider },
})
export default class DeviceOverviewRoomTargetCard extends Vue {
  @Prop() title_number;
  @Prop() text_font_size;
  @Prop() val;
  @Prop() min;
  @Prop() max;
  @Prop() step;
  @Prop() indoor;
  @Prop({default: 12}) cols;

  data() {
    return {
      currentValue: this.val,
    };
  }

  formatValue() {
    return this.currentValue.toFixed(1) + " °C";
  }

  sliderValueChange() {
    this.$emit("roomTargetValChange", this.currentValue);
  }
}
</script>

<style lang="scss">
@import "../../styles/app";

.dashboard-page {
  .page-title {
    padding-bottom: 20px;
    @include media-breakpoint-up(sm) {
      padding-bottom: 0;
    }
  }
  .apexcharts-legend-series {
    padding: 2px 5px 3px;
    display: flex;
    align-items: center;
    .apexcharts-legend-marker {
      margin-right: 8px;
    }
  }
  .main-chart-select {
    font-size: 1rem;
    margin-right: 10px;
    fieldset {
      border-width: 1px !important;
      border-color: $text-grey !important;
      color: $text-grey !important;
    }
    .v-select__selection {
      color: $text-color !important;
    }
  }
  .v-card {
    .v-card__text {
      .subtext {
        font-size: 1.142rem;
        font-weight: 400;
        color: $title-grey;
      }
      .subtext-index {
        font-size: 11px;
        color: $text-color;
        font-weight: 400;
      }
    }
  }
}

.v-menu__content {
  box-shadow: $card-shadow !important;
  .v-list-item__content .v-list-item__title {
    font-size: 1rem;
    font-weight: 400;
  }
}
.button-shadow {
  box-shadow: $button-shadow;
}

.v-slider__thumb {
  height: 16px !important;
  width: 16px !important;
}

.v-slider--horizontal .v-slider__track-container {
  height: 5px !important;
}

.slider {
  height: 170px;
}
</style>
