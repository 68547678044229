import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators'
import JobsApi from '@/api/jobs-api'
import { TimeUtil } from '@/helpers/TimeUtil'
import moment from "moment";
import { installationsModule } from '@/store/modules/store-accessor'

@Module({ namespaced: true, name: 'jobs' })
export default class JobsModule extends VuexModule {
  public jobs: JobDto[] = [];
  public currJob: JobDto = {} as JobDto;
  public isLoadingTable = true;


  @Action({ commit: 'setJobInfo'})
  async getJobs (): Promise<JobDto[]> {

    const jobApi = new JobsApi()
    const jobs = await jobApi.getAllJobs();

    return jobs
  }

  @Action({ commit: 'setJobDetails'})
  async getJobDetails (currentJobId: string): Promise<JobDto> {

    const jobApi = new JobsApi()
    const jobInfo = await jobApi.getJob(currentJobId);
    return jobInfo
  }

  @Action
  async createJobWithActions(data: Record<string, any>) {
   
    data.assignee_id = data.assignee_id.id;
    data.actions = data.type_id;
    data.client_paid = data.radioValue == 'user' ? true : false;
    data.payment_type_id = data.payment_type.id;
    if (data.cost == "") {
      delete data.cost;
    }
    delete data.is_paid;
    delete data.radioValue;
    delete data.type_id;

    const jobApi = new JobsApi()
    await jobApi.createJobWithActions(data)
    await installationsModule.getInstallationInfo(data.installation_id);
  }

  @Action
  async editJob(data: Record<string, any>) {

    const jobApi = new JobsApi()
    await jobApi.updatejob(data.id, data)
    await installationsModule.getInstallationInfo(data.installation_id);
  }

  @Action
  async deleteJob(data: Record<string, any>) {

    const jobApi = new JobsApi()
    await jobApi.deleteJob(data.id)
    await installationsModule.getInstallationInfo(data.installation_id);
  }

  @Action
  async createAppointmentToJob(data: Record<string, any>) {
   
    data.assignee_id = data.assignee_id.id;
    data.type_id = data.type_id.id;
    data.duration = data.duration.dur;
    data.scheduled_start = moment(data.scheduled_start + " " + data.scheduled_start_time);
    delete data.scheduled_start_time

    const jobApi = new JobsApi()
    await jobApi.createJobAppointment(data.id, data)
    await installationsModule.getInstallationInfo(data.installation_id);
  }

  @Action
  async createActionsToJob(data: Record<string, any>) {

    data.assignee_id = data.assignee_id.id;
    data.actions = data.type_id;
    delete data.type_id;

    const jobApi = new JobsApi()
    await jobApi.createJobActions(data.id, data)
    await installationsModule.getInstallationInfo(data.installation_id);
  }

  @Action
  async createPaymentToJob(data: Record<string, any>) {
   
    data.assignee_id = data.assignee_id.id;
    data.client_id = data.client.id;
    data.payment_type_id = data.payment_type.id;

    const jobApi = new JobsApi()
    await jobApi.createJobPayment(data.id, data)
    await installationsModule.getInstallationInfo(data.installation_id);
  }

  @Mutation
  public setJobInfo (jobs: JobDto[]): void {
    try {
      jobs.map(item => {
        item.readable_created_at = `${TimeUtil.formatDateTime(item.created_at)}`
      })
      this.jobs = jobs
      this.isLoadingTable = false;
    } catch (error) {
      this.isLoadingTable = false;
    }
  }

  @Mutation
  public setJobDetails (job: JobDto): void {
    this.currJob = job;
  }

  @Mutation
  public setIsLoadingTable(isLoadingTable: boolean): void {
    this.isLoadingTable = isLoadingTable
  }

}
