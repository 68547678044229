export default {
  light: {
    primary: "#284BAE",
    secondary: "#f6f7ff",
    error: "#FF4020",
    warning: "#FFC260",
    success: "#4caf50",
    info: "#0F68DB",
    textColor: "#B9B9B9",
    iconColor: "#FFFFFF",
    grayBold: "#4a4a4a",
    textColor2: "#6e6e6e",
    textGrey: "#b9b9b9",
    titleGrey: "#4a4a4a",
    whitesmoke: "#f5f5f5",
  },
};
