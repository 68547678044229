
import { Component, Vue, Prop } from 'vue-property-decorator'
import DevicesSideview from '@/components/sideviews/DevicesSideview.vue'
import DistributorDeviceSideview from '@/components/sideviews/DistributorDeviceSideview.vue'
import ClientsSideview from '@/components/sideviews/ClientsSideview.vue'
import { commonModule } from '@/store/modules/store-accessor'
import InstallationsSideview from '@/components/sideviews/InstallationsSideview.vue'

@Component({
  components: {
    DevicesSideview,
    ClientsSideview,
    DistributorDeviceSideview,
    InstallationsSideview
  }
})
export default class TheSideview extends Vue {
  get sideviewName () {
    return commonModule.sideview.name
  }

  get sideviewPayload () {
    return commonModule.sideview.payload
  }

  closeSideview() {
    commonModule.hideSideview();
  }

};
