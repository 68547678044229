
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import ApexChart from "vue-apexcharts";

@Component({
  components: { ApexChart },
})
export default class DashboardCardStats extends Vue {
  @Prop() readonly title: string;
  @Prop() readonly title_number: string;
  @Prop() readonly card_icon: string;
  @Prop() readonly card_icon_color: string;
  @Prop() readonly text_font_size: string;
  @Prop({default: 3}) readonly card_size: string;
}
