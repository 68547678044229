
import { Component, Vue, Prop } from 'vue-property-decorator'
import { TimeUtil } from '@/helpers/TimeUtil'
import DashboardCardBarChart from "@/components/Cards/DashboardCardBarChart.vue";
import DashboardCardPieChart from "@/components/Cards/DashboardCardPieChart.vue";
import DeviceOverviewCard from "@/components/Cards/DeviceOverviewCard.vue";
import DeviceOverviewSwitchCard from "@/components/Cards/DeviceOverviewSwitchCard.vue";
import DeviceOverviewButtonCard from "@/components/Cards/DeviceOverviewButtonCard.vue";
import DeviceOverviewHeatingBalanceSliderCard from "@/components/Cards/DeviceOverviewHeatingBalanceSliderCard.vue";
import DeviceOverviewSliderCard from "@/components/Cards/DeviceOverviewSliderCard.vue";
import DashboardCardFaultsPieChart from "@/components/Cards/DashboardCardFaultsPieChart.vue";
import DeviceOverviewRoomTargetCard from "@/components/Cards/DeviceOverviewRoomTargetCard.vue";
import DeviceOverviewRoomTargetLimitsCard from "@/components/Cards/DeviceOverviewRoomTargetLimitsCard.vue";
import { devicesModule } from '@/store/modules/store-accessor'

@Component({
  components: {
    DashboardCardBarChart,
    DashboardCardPieChart,
    DeviceOverviewCard,
    DeviceOverviewSwitchCard,
    DeviceOverviewButtonCard,
    DeviceOverviewHeatingBalanceSliderCard,
    DeviceOverviewSliderCard,
    DeviceOverviewRoomTargetCard,
    DeviceOverviewRoomTargetLimitsCard,
    DashboardCardFaultsPieChart,
  }
})

export default class HeatingControllerControlView
 extends Vue {
  @Prop() device: Record<string, any>;


  formatDate (date: Date): string {
    return TimeUtil.formatDateTime(date)
  }

  weatherAdaptationUpperLimitChange(val: string): void {
    let data = {
        action: "ch_ub",
        action_value: val,
    }
    devicesModule.updateDeviceConfig(data);
  }

  heatingControlChange(val: string) : void {
    let data = {
        action: "heat_set",
        action_value: val,
    }
    devicesModule.updateDeviceConfig(data);
  }

  childLockControlChange(val: string) : void {
    let data = {
        action: "chl_lck",
        action_value: val ? 1 : 0
    }
    devicesModule.updateDeviceConfig(data);
  }

  wiredThermostatLockControlChange(val: string) : void {
    let data = {
        action: "t_r_overr",
        action_value: val ? 1 : 0
    }
    devicesModule.updateDeviceConfig(data);
  }


  rfFailsafeControlChange(val: string) : void {
    let data = {
        action: "rf_failsafe",
        action_value: val ? 1 : 0
    }
    devicesModule.updateDeviceConfig(data);
  }

  deadbandEnableControlChange(val: string) : void {
    let data = {
        action: "db_en",
        action_value: val ? 1 : 0
    }
    devicesModule.updateDeviceConfig(data);
  }

  deadbandControlChange(val: string) : void {
    let data = {
        action: "db",
        action_value: val
    }
    devicesModule.updateDeviceConfig(data);
  }

  hotWaterControlChange(val: string) : void {
    let data = {
        action: "water_set",
        action_value: val,
    }
    devicesModule.updateDeviceConfig(data);
  }

  weatherAdaptationChange(val: boolean) : void {
    let data = {
       action: "bypass",
       action_value: val ? 1 : 2
    }
    devicesModule.updateDeviceConfig(data);
  }

  maxHotWaterChange(val: string) : void {
    let data = {
        action: "t_dhw_set",
        action_value: val,
    }
    devicesModule.updateDeviceConfig(data);
  }

  fixedBoilerTemperatureChange(val: string) : void {
    let data = {
        action: "t_set",
        action_value: val,
    }
    devicesModule.updateDeviceConfig(data);
  }

  heatingBalanceChange(val: string) : void {
    let data = {
        action: "otc_cur",
        action_value: val,
    }
    devicesModule.updateDeviceConfig(data);
  }

  weatherAdaptationLowerLimitChange(val: string): void {
    let data = {
        action: "ch_lb",
        action_value: val,
    }
    devicesModule.updateDeviceConfig(data);
  }

  deviceReset(val: boolean): void {
    let data = {
        action: "remote_reset",
        action_value: true,
    }
    devicesModule.updateDeviceConfig(data);
  }

  rfModulePair(val: boolean): void {
    let data = {
        action: "pair_rf_thermostat",
        action_value: true,
    }
    devicesModule.updateDeviceConfig(data);
  }

  resetThermostatOffset(val: boolean): void {
    let data = {
        action: "t_r_ofst",
        action_value: 0,
    }
    devicesModule.updateDeviceConfig(data);
  }

  roomTargetChange(val: number): void {
    let data = {
        action: "t_r_set",
        action_value: val,
    }
    devicesModule.updateDeviceConfig(data);
  }

  roomLowerLimitSet(val: number): void {
    let data = {
        action: "t_r_set_min",
        action_value: val,
    }
    devicesModule.updateDeviceConfig(data);
  }

  roomUpperLimitSet(val: number): void {
    let data = {
        action: "t_r_set_max",
        action_value: val,
    }
    devicesModule.updateDeviceConfig(data);
  }
};
