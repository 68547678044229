import { BaseApi } from '@/helpers/base-api'

export default class AppointmentApi extends BaseApi {
  constructor () {
    super('/api/v1/appointments')
    this.errorMessage = 'There was an issue when trying to fetch the appointments. If this error persists, please contact our support.'
  }

  public getAllAppointments = (query: string): Promise<AppointmentDto[]> => this.instance.get(`${query}`)
  public getAllPendingAppointments = (): Promise<AppointmentDto[]> => this.instance.get(`/pending`)
  
  public createAppointment = (data: Record<string, unknown>): Promise<JSON> => this.instance.post('', data)

  public updateAppointment = (appointmentId: string, data: Record<string, unknown>): Promise<JSON> => this.instance.patch(`/${appointmentId}`, data)

  public endAppointment = (appointmentId: string, data: Record<string, unknown>): Promise<JSON> => this.instance.patch(`/${appointmentId}/end`, data)

  public deleteAppointment = (appointmentId: string): Promise<JSON> => this.instance.delete(`/${appointmentId}`)

}
