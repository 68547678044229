
import { Component, Vue, Prop } from 'vue-property-decorator'
import { commonModule, installationsModule, devicesModule } from '@/store/modules/store-accessor'

@Component
export default class DeleteInstallationModal extends Vue {
  @Prop()
  payload!: any;

  async mounted() {
    commonModule.initSnackbar({});
  }

  async hideModal () {
    commonModule.hideModal();
  }

  async hideSideView() {
    commonModule.hideSideview();
  }

  async doDeleteDevice () {
    devicesModule.deleteDevice(this.payload.device)
    this.hideModal();
    this.hideSideView();
  }
};
