
import { Component, Vue, Prop } from 'vue-property-decorator'
import { TimeUtil } from '@/helpers/TimeUtil'
import HeatingControllerControlView from './heating_controller_ot/HeatingControllerControlView.vue';
import IAQControlView from './iaq/IAQControlView.vue';
import SinglePhaseEnergyMeterControlView from './energy_meter/SinglePhaseEnergyMeterControlView.vue';
import ThreePhaseEnergyMeterControlView from './energy_meter/ThreePhaseEnergyMeterControlView.vue';
import MBControlView from './heating_controller_mb/MBControlView.vue';

@Component({
  components: {
    HeatingControllerControlView,
    MBControlView,
    IAQControlView,
    SinglePhaseEnergyMeterControlView,
    ThreePhaseEnergyMeterControlView,
  }
})

export default class DevicesSideviewGeneralTab extends Vue {
  @Prop() device: Record<string, any>;      
};
