import {
  VuexModule,
  Module,
  Action,
  Mutation,
} from "vuex-module-decorators";
import AppointmentApi from "@/api/appointment-api";
import { formatDate, formatDateCalendar, addOpacity } from "../../helpers/data";
import moment from "moment";
import { installationsModule } from '@/store/modules/store-accessor'

  @Module({ namespaced: true, name: "appointments" })
  export default class AppointmentsModule extends VuexModule {
    public appointments: AppointmentDto[] = [];
    public pendingAppointments: AppointmentDto[] = [];
    public calendarAppointments: CalendarAppointmentDto[] = [];
    public isLoading = true;
    public isLoadingPendingTable = true;

  @Action({ commit: "setAppointments" })

  async getAppointmentsAction(query: string): Promise<AppointmentDto[]> {

    const appointmentApi = new AppointmentApi();
    const appointments = await appointmentApi.getAllAppointments(query);
    this.setIsLoading(false);
    return appointments;
  }

  @Action({ commit: "setPendingAppointments" })

  async getPendingAppointmentsAction(): Promise<AppointmentDto[]> {

    const appointmentApi = new AppointmentApi();
    const pendingAppointments = await appointmentApi.getAllPendingAppointments();
    this.setIsLoading(false);
    return pendingAppointments;
  }

  @Action({ commit: "setCalendarAppointments" })
  async getCallendarAppointmentsAction(query: string): Promise<AppointmentDto[]> {
    const appointmentApi = new AppointmentApi();
    const calendarAppointments = await appointmentApi.getAllAppointments(query);
    this.setIsLoading(false);
    return calendarAppointments;
  }

  @Action
  async createAppointment(data: Record<string, any>) {
   
    data.assignee_id = data.assignee_id.id;
    data.type_id = data.type_id.id;
    data.duration = data.duration.dur;
    data.scheduled_start = moment(data.scheduled_start + " " + data.scheduled_start_time);
    delete data.scheduled_start_time

    const appointmentApi = new AppointmentApi()
    await appointmentApi.createAppointment(data)
    await installationsModule.getInstallationInfo(data.installation_id);
    this.setIsLoading(false);
  }

  @Action
  async editAppointment(data: Record<string, any>) {
    if(data.assignee_id) {
      data.assignee_id = data.assignee_id.id
    } else {
      delete data.assignee_id;
    }
    data.type_id = data.type_id.id;

    if (data.scheduled_start) {
      data.scheduled_start = moment(data.scheduled_start + " " + data.scheduled_start_time);
      data.duration = data.duration.dur;
    } else {
      delete data.scheduled_start;
      delete data.duration;
      delete data.scheduled_start_time;
    }
    data.is_confirmed = data.appointment_is_confirmed;
    data.notes = data.appointment_notes;

    delete data.appointment_notes;
    delete data.appointment_is_confirmed;
    delete data.appointment_status_id;

    const appointmentApi = new AppointmentApi()
    await appointmentApi.updateAppointment(data.id, data)
    await installationsModule.getInstallationInfo(data.installation_id);
    this.setIsLoading(false);
  }

  @Action
  async deleteAppointment(data: Record<string, any>) {
  
    const appointmentApi = new AppointmentApi()
    await appointmentApi.deleteAppointment(data.id)
    await installationsModule.getInstallationInfo(data.installation_id);
    this.setIsLoading(false);
  }

  @Action
  async cancelAppointment(data: Record<string, any>) {
  
    const appointmentApi = new AppointmentApi()
    await appointmentApi.updateAppointment(data.id, {appointment_status_id: 1})
    await installationsModule.getInstallationInfo(data.installation_id);
    this.setIsLoading(false);
  }

  @Action
  async endAppointment(data: Record<string, any>) {

    data.assignee_id = data.assignee_id.id;
    data.actions = data.type_id;
    data.payment_type_id = data.payment_type.id;
    data.client_paid = data.radioValue == 'user' ? true : false;
    if (data.cost == "") {
      delete data.cost;
    }
    delete data.is_paid;
    delete data.radioValue;
    delete data.type_id;
  
    const appointmentApi = new AppointmentApi()
    await appointmentApi.endAppointment(data.id, data)
    await installationsModule.getInstallationInfo(data.installation_id);
    this.setIsLoading(false);
  }

  @Mutation
  public setAppointments(appointments: AppointmentDto[]): void {
    this.appointments = appointments;
  }

  @Mutation
  public setPendingAppointments(pendingAppointments: AppointmentDto[]): void {
    try {
      this.pendingAppointments = pendingAppointments;
      this.isLoadingPendingTable = false;
    } catch (error) {
      this.isLoadingPendingTable = false;
    }
  }

  @Mutation
  public setCalendarAppointments(calendarAppointments: CalendarAppointmentDto[]): void {
    const events = [];
    for (let i = 0; i < calendarAppointments.length; i++) {
  
    
     const first_formatted = formatDateCalendar(
      calendarAppointments[i].scheduled_start
     );
     const second_formatted = formatDateCalendar(
      calendarAppointments[i].scheduled_end
     );
  
     const first = new Date(first_formatted);
     const second = new Date(second_formatted);
     events.push({
       name: calendarAppointments[i].type_name,
       start: first,
       end: second,
       color: calendarAppointments[i].appointment_status_id == 4 ? addOpacity(calendarAppointments[i].type_color, 0.3): calendarAppointments[i].appointment_status_id == 1 ? addOpacity("#000000", 0.2) : calendarAppointments[i].type_color,
       details:
         ("0" + first.getHours()).slice(-2) +
         ":" +
         ("0" + first.getMinutes()).slice(-2) +
         " - " +
         ("0" + second.getHours()).slice(-2) +
         ":" +
         ("0" + second.getMinutes()).slice(-2),
       user: calendarAppointments[i].assignee_id,
       notes: calendarAppointments[i].notes,
       address: calendarAppointments[i].address?.split(',').pop(),
       client_name: calendarAppointments[i].client_name,
       tag_id: calendarAppointments[i].tag_id?.split("_").pop(),
       installation_item: calendarAppointments[i].installation_item,
       area: calendarAppointments[i].area,
       installation_id: calendarAppointments[i].installation_id,
       timed: true,
       assignee_id: calendarAppointments[i].assignee_id,
       status_id: calendarAppointments[i].appointment_status_id,
       category: calendarAppointments[i].assignee_id,
     });
   }

    this.calendarAppointments = events;
  }

  @Mutation
  public setIsLoading(isLoading: boolean): void {
    this.isLoading = isLoading
  }

  @Mutation
  public setIsLoadingPendingTable(isLoadingPendingTable: boolean): void {
    this.isLoadingPendingTable = isLoadingPendingTable;
  }

}