import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators'
import PaymentsApi from '@/api/payments-api'
import { TimeUtil } from '@/helpers/TimeUtil'
import { installationsModule } from '@/store/modules/store-accessor'

@Module({ namespaced: true, name: 'payments' })
export default class PaymentsModule extends VuexModule {
  public payments: PaymentDto[] = [];
  public isLoadingTable = true;

  @Action({ commit: 'setPayments'})
  async getPaymentsAction (query: string): Promise<PaymentDto[]> {
    const paymentsApi = new PaymentsApi()
    const payments = await paymentsApi.getAllPayments(query)
    return payments
  }

  @Action
  async deletePayment(data: Record<string, any>) {

    const paymentsApi = new PaymentsApi()
    await paymentsApi.deletePayments(data.id)
    await installationsModule.getInstallationInfo(data.installation_id);
  }

  @Mutation
  public setPayments (payments: PaymentDto[]): void {
    try {
      payments.map(item => {
        item.readable_created_at = `${TimeUtil.formatDateTime(item.created_at)}`;
        item.tag_split = item.tag_id.split('_').pop();
      })
      this.payments = payments
      this.isLoadingTable = false;
    } catch (error) {
      this.isLoadingTable = false;
    }
  }

  @Mutation
  public setIsLoadingTable(isLoadingTable: boolean): void {
    this.isLoadingTable = isLoadingTable
  }
}