import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import ComboApi from '@/api/combo-api'

@Module({ name: 'common'})
export default class CommonModule extends VuexModule {
    user: User = { name: "", email: ""};
    modal: ModalObject =  { name: '', payload: {} }
    portalAccess = false;
    sideview: SideviewObject = {name: '', payload: {} };
    secondaryModal: ModalObject = { name: '', payload: {} };
    snackbar: SnackbarObject =  { visible: false, color: "#000000", timeout: 2000, text: "test" }

    public personnelCombo = []
    public actionCombo = []
    public clientsCombo = []
    public fuelTypeCombo = []
    public contactTypeCombo = []
    public placementTypeCombo = []
    public itemTypeCombo = []
    public appointmentStatusCombo = []
    public itemsCombo = []
    public durationCombo = []
    public installationsCombo = []
    public paymentTypeCombo = []
    public isLoading = true;

    @Action({ commit: 'setPersonnelCombo'})
    async getPersonnelCombo (): Promise<any[]> {
      const comboApi = new ComboApi()
      const personnelCombo = await comboApi.getPersonnelCombo();
      this.setIsLoading(false);
      return personnelCombo
    }
  
    @Action({ commit: 'setActionCombo'})
    async getActionCombo (): Promise<any[]> {
      const comboApi = new ComboApi()
      const actionCombo = await comboApi.getActionTypeCombo();
      this.setIsLoading(false);
      return actionCombo
    }

    @Action({ commit: 'setPaymentTypeCombo'})
    async getPaymentTypeCombo (): Promise<any[]> {
      const comboApi = new ComboApi()
      const paymentTypeCombo = await comboApi.getPaymentTypeCombo();
      return paymentTypeCombo
    }
  
    @Action({ commit: 'setClientsCombo'})
    async getClientsCombo (): Promise<any[]> {
      const comboApi = new ComboApi()
      const clientsCombo = await comboApi.getClientsCombo();
      this.setIsLoading(false);
      return clientsCombo
    }
  
    @Action({ commit: 'setFuelTypeCombo'})
    async getFuelTypeCombo (): Promise<any[]> {
      const comboApi = new ComboApi()
      const fuelTypeCombo = await comboApi.getFuelTypeCombo();
      this.setIsLoading(false);
      return fuelTypeCombo
    }
  
    @Action({ commit: 'setContactTypeCombo'})
    async getContactTypeCombo (): Promise<any[]> {
      const comboApi = new ComboApi()
      const contactTypeCombo = await comboApi.getContactTypeCombo();
      this.setIsLoading(false);
      return contactTypeCombo
    }
  
    @Action({ commit: 'setPlacementTypeCombo'})
    async getPlacementTypeCombo (): Promise<any[]> {
      const comboApi = new ComboApi()
      const placementTypeCombo = await comboApi.getPlacementTypeCombo();
      this.setIsLoading(false);
      return placementTypeCombo
    }
  
    @Action({ commit: 'setItemTypeCombo'})
    async getItemTypeCombo (): Promise<any[]> {
      const comboApi = new ComboApi()
      const itemTypeCombo = await comboApi.getItemTypeCombo();
      this.setIsLoading(false);
      return itemTypeCombo
    }
  
    @Action({ commit: 'setAppointmentStatusCombo'})
    async getAppointmentStatusCombo (): Promise<any[]> {
      const comboApi = new ComboApi()
      const appointmentStatusCombo = await comboApi.getAppointmentStatusCombo();
      this.setIsLoading(false);
      return appointmentStatusCombo
    }
  
    @Action({ commit: 'setDurationCombo'})
    getDurationCombo (): any {
      const durationCombo: any = [];
      // duration_minutes and duration_text MUST have the same length
      // in order to work, values must be in the same index in both of the lists
      const duration_minutes = [30, 60, 90, 120, 150, 180, 240, 300, 360, 420, 480];
      const duration_text    = ["30m", "1h", "1h 30m", "2h", "2h 30m", "3h", "4h", "5h", "6h", "7h", "8h"];

      for(let i = 0; i < duration_minutes.length; i++) {
        durationCombo.push({
          "dur": duration_minutes[i],
          "text": duration_text[i]
        });
      }
      return durationCombo
    }

    @Action({ commit: 'setInstallationsCombo'})
    async getInstallationsCombo (): Promise<any[]> {
      const comboApi = new ComboApi()
      const installationsCombo = await comboApi.getInstallationsmCombo();
      return installationsCombo
    }

    @Mutation
    public setPaymentTypeCombo (paymentTypeCombo: []): void {
      this.paymentTypeCombo = paymentTypeCombo
    }

    @Mutation
    public setInstallationsCombo (installationsCombo: []): void {
      this.installationsCombo = installationsCombo
    }

    @Mutation
    public setPersonnelCombo (personnel: []): void {
      this.personnelCombo = personnel
    }
  
    @Mutation
    public setActionCombo (action: []): void {
      this.actionCombo = action;
    }
  
    @Mutation
    public setClientsCombo (clients: []): void {
      this.clientsCombo = clients;
    }
  
    @Mutation
    public setFuelTypeCombo (fuelTypes: []): void {
      this.fuelTypeCombo = fuelTypes;
    }
  
    @Mutation
    public setContactTypeCombo (contactTypes: []): void {
      this.contactTypeCombo = contactTypes;
    }
  
    @Mutation
    public setPlacementTypeCombo (placementTypes: []): void {
      this.placementTypeCombo = placementTypes;
    }
  
    @Mutation
    public setItemTypeCombo (itemTypes: []): void {
      this.itemTypeCombo = itemTypes;
    }
  
    @Mutation
    public setAppointmentStatusCombo (appointmentStatus: []): void {
      this.appointmentStatusCombo = appointmentStatus;
    }

    @Mutation
    public setDurationCombo (durationCombo: []): void {
      this.durationCombo = durationCombo;
    }
    
    @Action({ commit: 'setInventoryItemCombo'})
    async getInventoryItemCombo (): Promise<any[]> {
      const comboApi = new ComboApi()
      const itemsCombo = await comboApi.getInventoryItemCombo();
      return itemsCombo
  }

  @Mutation
  public setInventoryItemCombo (action: []): void {
    this.itemsCombo = action
  }

    @Mutation
    showModal(modal: any) {
      this.modal = modal
    }

    @Mutation
    hideModal() {
      this.modal = { name: '', payload: {} }
    }

    @Mutation
    showSecondModal(secondModal: any) {
      this.secondaryModal = secondModal
    }

    @Mutation
    hideSecondModal() {
      this.secondaryModal = { name: '', payload: {} }
    }

    @Mutation
    initSnackbar(snackbar: any) {
      this.snackbar = snackbar
    }

    @Mutation
    hideSnackbar() {
      this.snackbar = { visible: false, color: "#ffffff", timeout: 200, text: "" }
    }

    @Mutation
    showSideview(sideview: any) {
      this.sideview = sideview
    }

    @Mutation
    hideSideview() {
      this.sideview = { name: '', payload: {} }
    }

    @Mutation
    setUserInfo(name: string, email: string) {
      this.user.name = name;
      this.user.email = email;
    }

    @Mutation
    setPortalAccess(portalAccess: boolean) {
      this.portalAccess = portalAccess;
    }

    @Mutation
    public setIsLoading(isLoading: boolean): void {
      this.isLoading = isLoading
    }
}
