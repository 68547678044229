
import config from "../../config";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import {  companyModule } from "@/store/modules/store-accessor"
import KeycloakSession from '@/helpers/keycloak-session'

@Component
export default class Footer extends Vue {
  links = [
    {
      text: "Official Distributor Gas Technic",
      link: "https://gastechnic.gr/",
    },
    { text: "domX", link: "https://mydomx.eu/" },
    // { text: "Blog", link: "https://flatlogic.com/blog" },
  ];

  get distributorLogoLink() {
    // return companyModule.company.distributor_logo_link;
    if(this.isDistributor) {
      return companyModule.company.company_logo_link;
    }
    else {
      return companyModule.company.distributor_logo_link;
    }
  }

  get officialDistributor() {
    // return companyModule.company.distributor_logo_link;
    if(this.isDistributor) {
      //This is a hack...for demos...this should just return true
       if (companyModule.company.email == 'info@domx.io') {
        return true;
       }
    }
    return false;
  }

  get distributorLink() {
    // return companyModule.company.distributor_link;
    if(this.isDistributor) {
      return '#'
    }
    else {
      return companyModule.company.distributor_link;
    }
  }

  get kcSession() {
    return new KeycloakSession()
  }

  get isDistributor() {
    return this.kcSession.userHasRole('distributor');
  }

  get isTechnicianCompany () {
    return this.kcSession.userHasRole('technician_company');
  }

  logo:any = require("@/assets/img/new_logo_domX.png");

  text_color = config.light.textColor;
  icons = [
    {
      icon: "mdi-facebook",
      link: "https://www.facebook.com/GasTechnic/",
    },
    {
      icon: "mdi-instagram",
      link: "https://www.instagram.com/gastechnic/",
    },
    // { icon: "mdi-twitter", link: "https://www.facebook.com/flatlogic" },
    // { icon: "mdi-github", link: "https://github.com/flatlogic/" },
  ];
}
