
import { Component, Vue, Prop } from 'vue-property-decorator'
import { TimeUtil } from '@/helpers/TimeUtil'
import DashboardCardBarChart from "@/components/Cards/DashboardCardBarChart.vue";
import DashboardCardPieChart from "@/components/Cards/DashboardCardPieChart.vue";
import DeviceOverviewCard from "@/components/Cards/DeviceOverviewCard.vue";
import DeviceOverviewSwitchCard from "@/components/Cards/DeviceOverviewSwitchCard.vue";
import DeviceOverviewButtonCard from "@/components/Cards/DeviceOverviewButtonCard.vue";
import DeviceOverviewHeatingBalanceSliderCard from "@/components/Cards/DeviceOverviewHeatingBalanceSliderCard.vue";
import DashboardCardFaultsPieChart from "@/components/Cards/DashboardCardFaultsPieChart.vue";
import DeviceOverviewRoomTargetCard from "@/components/Cards/DeviceOverviewRoomTargetCard.vue";
import { devicesModule } from '@/store/modules/store-accessor'

@Component({
  components: {
    DashboardCardBarChart,
    DashboardCardPieChart,
    DeviceOverviewCard,
    DeviceOverviewSwitchCard,
    DeviceOverviewButtonCard,
    DeviceOverviewHeatingBalanceSliderCard,
    DeviceOverviewRoomTargetCard,
    DashboardCardFaultsPieChart,
  }
})

export default class SinglePhaseEnergyMeterControlView extends Vue {
  @Prop() device: Record<string, any>;


  formatDate (date: Date): string {
    return TimeUtil.formatDateTime(date)
  }

  setRelay(val: string): void {
    let data = {
        action: "relay",
        action_value: val ? "on" : "off",
    }
    devicesModule.updateDeviceConfig(data);
  }
};
