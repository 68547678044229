import { ActionType } from "@/types";
import {
  VuexModule,
  Module,
  Action,
} from "vuex-module-decorators";
import ActionsApi from "@/api/actions-api";
import { installationsModule } from '@/store/modules/store-accessor'

export interface ActionState {
  allActions: ActionType[];
}

@Module({ name: "actions" })
export default class ActionModule extends VuexModule implements ActionState {
  public allActions: ActionType[] = [];

  @Action
  async deleteAction(data: Record<string, any>) {
    const actionsApi = new ActionsApi()
    await actionsApi.deleteAction(data.id)
    await installationsModule.getInstallationInfo(data.installation_id);
  }

}
