
import { Component, Vue, Prop } from 'vue-property-decorator'
import { commonModule, clientsModule } from '@/store/modules/store-accessor'
import { primaryContactExists } from '@/helpers/data';

@Component
export default class AddClientModal extends Vue {
  @Prop()
  payload!: any;

  clientModel = {
    firstname: "", 
    lastname: "",
    email: "",
    id: "",
    contacts: [{
        id: Math.random() * -1, //Need Random and non-integer and also negative, for this to work with the backend properly
        contact_type: { contact_info_type: "", id: 0 },
        contact_type_id: 0,
        is_primary: true,
        description: '',
        phone: ''
    }]
  }

  get contactTypeCombo() {
    for (let i = 0; i < commonModule.contactTypeCombo.length; i++) {
      (commonModule.contactTypeCombo[i]["contact_info_type"] as any) = this.$t(commonModule.contactTypeCombo[i]["contact_info_type"]);
    }
    return commonModule.contactTypeCombo;
  }

  firstnameRule= [
    (val: any) => !!val || this.$i18n.t("firstname_required"),
    (val: any) => val.length <= 45 || this.$i18n.t("client_form_name_length_rule"),
  ]
  lastnameRule= [
    (val: any) => !!val || this.$i18n.t("lastname_required"),
    (val: any) => val.length <= 45 || this.$i18n.t("client_form_lastname_length_rule"),
  ]
  emailRule:any =[
    (val: any) => val.length <= 70 || this.$i18n.t("client_form_email_length_rule"),
    (val: any) => {
      //eslint-disable-next-line
      const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
      if(val.length > 0) return reg.test(val) || this.$i18n.t("form_email_rule");
      else return true;
    },
  ]
  contactTypeRule= [
    (val: any) => (val || '').contact_info_type.length > 0 || this.$i18n.t("contact_type_required"),
  ]
  phoneRule= [
    (val: any) => !!val || this.$i18n.t("phone_required"),
    (val: any) => val.length <= 15 || this.$i18n.t("client_form_phone_length_rule"),
  ]

  onCheckboxChange(ev: any, item: any) {
    if(ev === true) {
        this.clientModel.contacts.forEach((element: any) => 
        {
          if (element.id === item.id) {
            element.is_primary = true;
          }
          else {
            element.is_primary = false;
          }
        });
    }

  }

  async hideModal () {
    commonModule.hideModal();
  }

  async mounted() {
    commonModule.initSnackbar({});
  }

  addPhone() {
    let newContact = {
        id: Math.random(),
        contact_type: { contact_info_type: "", id: 0 },
        contact_type_id: 0,
        is_primary: false,
        description: '',
        phone: ''
    } as any

    this.clientModel.contacts.push(newContact);
  }

  deletePhone(index: number) {
    if (this.clientModel.contacts.length > 1) {
      this.clientModel.contacts.splice(index, 1);
      if (!primaryContactExists(this.clientModel)) {
        this.clientModel.contacts[index].is_primary = true;
      }
    }
  }

  doAddClient () {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      this.clientModel.contacts.map((item) => {
        item.contact_type_id = item.contact_type.id
      })

      clientsModule.addClient(this.clientModel);
      this.hideModal();
    } 
  }
};
