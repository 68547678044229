import { render, staticRenderFns } from "./ClientsSideviewGeneralTab.vue?vue&type=template&id=ce801e36&scoped=true&class=fill-height"
import script from "./ClientsSideviewGeneralTab.vue?vue&type=script&lang=ts"
export * from "./ClientsSideviewGeneralTab.vue?vue&type=script&lang=ts"
import style0 from "./ClientsSideviewGeneralTab.vue?vue&type=style&index=0&id=ce801e36&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ce801e36",
  null
  
)

export default component.exports