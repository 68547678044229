
import Vue from "vue";

export default Vue.extend({
  name: "App",
  data: function () {
    const lang = localStorage.getItem("lang") || "gr";
    return { lang: lang };
  },
  methods: {
    handleChange(event: any) {
      localStorage.setItem("lang", event.target.value);
      window.location.reload();
    },
  },
});
