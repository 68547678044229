import { BaseApi } from '@/helpers/base-api'

export default class CompanyApi extends BaseApi {
  constructor () {
    super('/api/v1/company')
    this.errorMessage = 'There was an issue when trying to fetch the company information. If this error persists, please contact our support.'

  }

  public getCompanyInfo = (): Promise<CompanyInfoDto> => this.instance.get('')
  public getCompanyPersonnel = (): Promise<CompanyPersonnelDto[]> => this.instance.get('/personnel')
}
