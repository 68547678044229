
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class DeviceOverviewButtonCard extends Vue {
  @Prop() readonly title_number: string;
  @Prop() readonly subTitle: string;
  @Prop() readonly text_font_size: string;
  @Prop({default: 4}) cols: number; 
  @Prop({default: 'RESET'}) buttonMsg: string; 
  @Prop({default: 2}) loaderSecs: number; 

  buttonLoading = false;
  
  get isLoading() {
    return this.buttonLoading
  }

  onButtonClick() {
    this.buttonLoading = true;
    setTimeout(() => this.buttonLoading = false, this.loaderSecs * 1000) 

    this.$emit("buttonPressed");
  }
}
