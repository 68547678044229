
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { commonModule, clientsModule } from '@/store/modules/store-accessor'
import _ from 'lodash';
import { primaryContactExists } from '@/helpers/data';

@Component
export default class EditClientModal extends Vue {
  @Prop()
  payload!: any;

  get currClient() {
    let client = _.cloneDeep(clientsModule.currentClient);
    return client;
  }

  copyCurrClient(currentClientContacts: any) {
    if(currentClientContacts !== undefined){
      for (let i = 0; i < currentClientContacts.length; i++) {
        currentClientContacts[i].contact_type = this.$t(currentClientContacts[i].contact_type);
      }
      return [...currentClientContacts]
    }
    else{
      return []
    }
  }

  clientModel = {
    firstname: this.currClient.info.firstname, 
    lastname:this.currClient.info.lastname,
    email: this.currClient.info.email,
    id: this.currClient.info.id,
    contacts: this.copyCurrClient(this.currClient.contacts),
    view: this.payload.view
  }

  get contactTypeCombo() {
    for (let i = 0; i < commonModule.contactTypeCombo.length; i++) {
      (commonModule.contactTypeCombo[i]["contact_info_type"] as any) = this.$t(commonModule.contactTypeCombo[i]["contact_info_type"]);
    }
    return commonModule.contactTypeCombo;
  }

  firstnameRule= [
    (val: any) => !!val || this.$i18n.t("firstname_required"),
    (val: any) => val.length <= 45 || this.$i18n.t("client_form_name_length_rule"),
  ]
  lastnameRule= [
    (val: any) => !!val || this.$i18n.t("lastname_required"),
    (val: any) => val.length <= 45 || this.$i18n.t("client_form_lastname_length_rule"),
  ]
  emailRule:any =[
    (val: any) => {
      if(val != null) return val.length <= 70 || this.$i18n.t("client_form_email_length_rule");
      else return true;
    },
    (val: any) => {
      //eslint-disable-next-line
      const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
      if(val != null && val.length > 0) return reg.test(val) || this.$i18n.t("form_email_rule");
      else return true;
    },
  ]
  contactTypeRule= [
    (val: any) => val.contact_info_type != "" || this.$i18n.t("contact_type_required")
  ]
  phoneRule= [
    (val: any) => !!val || this.$i18n.t("phone_required"),
    (val: any) => val.length <= 15 || this.$i18n.t("client_form_phone_length_rule"),
  ]

  onCheckboxChange(ev: any, item: any) {
    if(ev === true) {
        this.clientModel.contacts.forEach((element: any) => 
        {
          if (element.id === item.id) {
            element.is_primary = true;
          }
          else {
            element.is_primary = false;
          }
        });
    }

  }

  async hideModal () {

    commonModule.hideModal();
  }

  async mounted() {
    commonModule.initSnackbar({});
  }

  addPhone() {
    let newContact = {
        id: Math.random() * -1, // Hack...Need Random and non-integer and also negative, for this to work with the backend properly
        contact_type: { contact_info_type: "", id: 0 },
        contact_type_id: 0,
        is_primary: false,
        description: '',
        phone: ''
    } as any

    if (this.clientModel.contacts.length == 0) {
      newContact.is_primary = true;
    }
    this.clientModel.contacts.push(newContact);
  }

  deletePhone(index: number) {
    if (this.clientModel.contacts.length > 1) {
      this.clientModel.contacts.splice(index, 1);
      if(!primaryContactExists(this.clientModel)) {
        this.clientModel.contacts[index].is_primary = true;
      }
    }
  }

  doEditClient () {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {

      this.clientModel.contacts.map((item) => {
        if(item.contact_type.id !== undefined) {
          item.contact_type_id = item.contact_type.id
        } 
      })
      
      clientsModule.editClient(this.clientModel);
      this.hideModal();
    } 
  }
};
