
import { Component, Vue, Prop } from 'vue-property-decorator'
import SinglePhaseEnergyMeterChartView from './energy_meter/SinglePhaseEnergyMeterChartView.vue';
import ThreePhaseEnergyMeterChartView from './energy_meter/ThreePhaseEnergyMeterChartView.vue';
import HeatingControllerChartView from './heating_controller_ot/HeatingControllerChartView.vue';
import IAQChartView from './iaq/IAQChartView.vue';
import MBChartView from './heating_controller_mb/MBChartView.vue';
@Component({
  components: {
    HeatingControllerChartView,
    IAQChartView,
    MBChartView,
    SinglePhaseEnergyMeterChartView,
    ThreePhaseEnergyMeterChartView
  }
})
export default class DevicesSideviewChartsTab extends Vue {
 @Prop() device: Record<string, any>;

}
