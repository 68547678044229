
import { Component, Vue, Watch } from 'vue-property-decorator'
import { commonModule, clientsModule, installationsModule, distributorModule } from '@/store/modules/store-accessor'
import Dropdown from '../components/dropdown/Dropdown.vue'
import DropdownItem from '../components/dropdown/DropdownItem.vue'


@Component({
  components: { Dropdown, DropdownItem }
})
export default class Companies extends Vue {
  tableheaders: any [] = [
      { text: this.$t("name"), sortable: true, class: 'row-style', value: 'entity_name' },
      { text: this.$t("phone"), align: 'left', sortable: true, class: 'row-style', value: 'contact' },
      { text: this.$t("email"), sortable: true, class: 'row-style', value: 'email' },
      { text: this.$t("devices_installed"), sortable: true, class: 'row-style', value: 'devices_installed' },      
      { text: '', align: 'right', sortable: false, class: 'row-style', value: 'id' } 
    ]

  model: any = {
    search: '',
    selected: [] as string[]
  }

  async mounted () {
    /*
     TODO: Handle clients properly when UX is clear.
     */
    distributorModule.getDistributorCompanies();
    commonModule.initSnackbar({});
  }

  get companiesLength() {
    return this.companies.length
  }

  get searchLabel() {
    return this.$t("clientsSearch")
  }

  get companies(){
    return distributorModule.distributorCompanies
  }

  get isLoadingTable() {
    return distributorModule.isLoadingTable;
  }

}
